<template>
  <div class="section-h100">
    <div class="background bg-signupscreen"></div>
    <div class="container-fluid">
      <div
        v-if="signup_step === 1"
        class="box-signin animate__animated animate__slideInUp animate__delay-0.7s"
      >
        <h2 class="box-header">{{ $t("Navbar.signin") }}</h2>
        <div class="box-content">
          <form class="form form-signup">
            <div class="inner">
              <div class="input-block" @click="getfocus('email')" id="email">
                <span class="input-text">{{ $t("Signup.email") }}</span>
                <input
                  type="text"
                  class="form-control int1"
                  v-model="signupData.email"
                  @keyup.enter="continuestep"
                />
              </div>
              <div class="input-block" @click="getfocus('pass')" id="pass">
                <span class="input-text">{{ $t("Signup.password") }}</span>
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control int2"
                  v-model="signupData.password"
                  @keyup.enter="continuestep"
                />
                <span class="icons icon-eye-close field-icon toggle-password">
                  <v-btn icon small @click="showPassword = !showPassword">
                    <v-icon>
                      {{ !showPassword ? "mdi-eye-off-outline" : "mdi-eye-outline" }}
                    </v-icon>
                  </v-btn>
                </span>
              </div>
            </div>

            <p class="text-right">
              <a @click="forgetPasswordFunc()">{{ $t("Signup.forget_password") }} (?)</a>
            </p>

            <div class="buttons">
              <button
                type="button"
                class="btn btn-lg w-100 gl-bt hover_btn"
                @click="continuestep()"
              >
                <span>{{ $t("Navbar.signin") }}</span>
              </button>
            </div>
          </form>
          <!--form-->
          <div class="label-or">
            <span>{{ $t("Signup.or") }}</span>
          </div>
          <p>{{ $t("Signup.continuewith") }}</p>
          <div class="followus">
            <a @click="loginwithGoogle()">
              <img
                class="svg-js gg"
                :src="require('@/assets/icons/icon-googleplus.svg')"
                alt
              />
              <img class="gg" :src="require('@/assets/icons/icon-googleplus.svg')" alt />
            </a>
            <a @click="loginwithFacebook()">
              <img
                class="svg-js fb"
                :src="require('@/assets/icons/icon-facebook.svg')"
                alt
              />
              <img class="fb" :src="require('@/assets/icons/icon-facebook.svg')" alt />
            </a>
            <a @click="loginwithTwitter()">
              <img
                class="svg-js tw"
                :src="require('@/assets/icons/icon-twitter.svg')"
                alt
              />
              <img class="tw" :src="require('@/assets/icons/icon-twitter.svg')" alt />
            </a>
          </div>
          <hr />
          <p class="m-0">
            {{ $t("Signup.policy1") }}
            <a @click="gosignup" class="mr-2">{{ $t("Signup.createaccount") }}</a>
            <br class="d-block d-md-none" />
            {{ $t("Signup.policy2") }}
            <br />
            <a @click="gotogeneralterms()">{{ $t("Signup.policy3") }}</a>
            {{ $t("Signup.policy4") }}
            <a @click="gotoserviceterms()">{{ $t("Signup.policy5") }}</a>
            {{ $t("Signup.policy6") }}
          </p>
          <hr />
          <p class="m-0 p-1 f12">{{ $t("Signup.sign") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";
import firebase from "firebase";

export default {
  name: "SigninScreen",
  metaInfo: {
    title: "Signin",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    loading: false,
    signup_step: 2,
    signupData: {
      email: "",
      password: "",
    },
    showPassword: false,
    rules: {
      required: (value) => !!value || "จำเป็น",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "อีเมลไม่ถูกต้อง";
      },
    },
    refCode: "",
    value_element: "",
    userData: null,
  }),
  created() {
    this.initSignup();
  },
  mounted() {
    this.colorHover();
    EventBus.$on("gotoSignin", this.initSignup);
  },
  computed: {},
  watched() {
    this.value_element;
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      // console.log('ttt', document.querySelector('.hover_btn'))
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus");
        element.classList.add("focus");
        this.value_element = val;
      }
    },
    initSignup() {
      const self = this;
      self.loading = true;
      self.signup_step = 1;
      // console.log(self.$store.state)
      setTimeout(() => (self.loading = false), 2000);
    },
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    gosignup() {
      var item = { link: "signup", name: "SignupScreen" };
      EventBus.$emit("changePathname", item);
    },
    gotogeneralterms() {
      window.open(`${window.location.origin || process.env.VUE_APP_WEB}/general`);
    },
    gotoserviceterms() {
      window.open(`${window.location.origin || process.env.VUE_APP_WEB}/privacy`);
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(String(email).toLowerCase());
    },
    async continuestep() {
      const self = this;
      EventBus.$emit("loadingPage");
      if (self.signupData.email && self.signupData.password) {
        // if (self.validateEmail(self.signupData.email) )
        if (!self.validateEmail(self.signupData.email)) {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Signup.invalidemail"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } else if (self.signupData.password.length < 8) {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Signup.passmore"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } else {
          EventBus.$emit("loadingPage");
          self.signinFunc();
        }
      } else {
        swal(
          self.$t("Alert.warn_title"),
          !self?.signupData?.email
            ? `${self.$t("Alert.pleasefill")}${self.$t("Signup.email")}`
            : !self?.signupData?.password
            ? `${self.$t("Alert.pleasefill")}${self.$t("Signup.password")}`
            : self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    async signinFunc() {
      const self = this;
      const item = {
        username: self.signupData.email.toLowerCase(),
        password: self.signupData.password,
        type: "provider",
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(process.env.VUE_APP_API + "/login", item);
        console.log("res: ", res);
        if (res.status == 200 && res.data.message == "login success") {
          console.log('start login')
          localStorage.setItem("Token", res.data.result.access_token);
          localStorage.setItem("userEmail", item.username);
          self.initUserData(
            res.data.result.access_token,
            res.data.result.property[0].properties[0],
            res.data.result.is_first_login,
            res.data.result.is_signup_success,
            res.data.result.property,
            email,
          );
        } else {
          console.log("login fail");
          swal(
            self.$t("Alert.warn_title"),
            res.data.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        console.log(error);
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    gofillmore(token, firstproperty, firstlogin, signupsuccess, property) {
      const self = this;
      var itemManagement = {
        link: "provider/management/property",
        name: "SigninPropertyManagementScreen",
        params: { userToken: token, propertyId: property[0]?.properties[0] },
      };
      var itemPropertyDetail = {
        link: "signup/propertydetail",
        name: "SignupPropertyDetailScreen",
        params: { userToken: token, propertyId: property[0]?.properties[0] },
      };
      if (signupsuccess || property?.length) {
        EventBus.$emit("changePathname", itemManagement);
      } else {
        if (property?.length) self.initFirstProperty(token, property, itemPropertyDetail);
        else self.gotoSelectPropertyTypeFunc(token);
      }
    },
    gotoSelectPropertyTypeFunc(token) {
      var item = {
        link: `signup/propertytype/${token}`,
        name: "SignupPropertyTypeScreen",
        params: { userToken: token },
      };
      EventBus.$emit("changePathname", item);
    },
    async initFirstProperty(userToken, propertyItems, linkItem) {
      const self = this;
      var temp = null;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${propertyItems[0]?.properties[0]}`,
          {
            headers: {
              Authorization: userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0];
          // console.log(temp);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        // console.log(temp?.step_signup);
        if (
          temp?.step_signup?.step1 &&
          temp?.step_signup?.step2 &&
          temp?.step_signup?.step3 &&
          temp?.step_signup?.step4 &&
          temp?.step_signup?.step5 &&
          temp?.step_signup?.step6 &&
          temp?.step_signup?.step7
        ) {
          EventBus.$emit("changePathname", linkItem);
        } else if (!temp?.step_signup?.step1) {
          self.gotoSelectPropertyTypeFunc(userToken);
        } else {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.usernotfound"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      }
    },
    async initUserData(
      userToken,
      firstProperty,
      firstLogin,
      signupSuccess,
      allProperty,
      email
    ) {
      const self = this;
      self.signupData.email = "";
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/my_user", {
          headers: {
            Authorization:
              userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          // console.log(res);
          self.userData = res?.data?.result || null;
          var found;
          if (firstProperty == null) {
             found = false;
          } else {
            found = true;
          }
          // const found = res?.data?.result?.property.some((p) => p?.properties?.length);
          // console.log(self.userData)
          if (self.userData?.role == "staff") {
            self.gofillmore(userToken, firstProperty, firstLogin, true, allProperty);
          } else if (found) {
            self.gofillmore(
              userToken,
              firstProperty,
              firstLogin,
              signupSuccess,
              allProperty
            );
          } else {
            self.signupData = {
              email: email,
              password: "",
              cfpassword: "",
              role: "provider",
            };
            swal(
              self.$t("Alert.warn_title"),
              self.$t("Alert.signupplease"),
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            ).then(() => self.gosignup());
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
      }
    },
    loginwithGoogle() {
      const self = this;
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(
          (user) => {
            // console.log('provider: ', provider, 'user: ', user)
            const authenData = {
              email: user?.additionalUserInfo?.profile?.email || "",
              accessToken: user?.credential?.accessToken || "",
            };
            self.googleSignIn(authenData.accessToken, authenData.email);
          },
          (err) => {
            swal(
              self.$t("Alert.error_title"),
              err.message || self.$t("Alert.tryagain"),
              self.$t("Alert.error_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          }
        );
    },
    async googleSignIn(accessToken, email) {
      const self = this;
      const obj = {
        access_token: accessToken,
        type: "provider",
      };
      localStorage.clear();
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(process.env.VUE_APP_API + "/login/google", obj);
        // console.log(res);
        if (res.status == 200) {
          EventBus.$emit("endloading");
          localStorage.setItem("Token", res.data.result.access_token);
          localStorage.setItem("userEmail", email);
          self.initUserData(
            res.data.result.access_token,
            res.data.result.property[0].properties[0],
            res.data.result.is_first_login,
            res.data.result.is_signup_success,
            res.data.result.property,
            email
          );
          // self.gofillmore(
          //   res.data.result.access_token,
          //   res.data.result.property[0].properties[0],
          //   res.data.result.is_first_login,
          //   res.data.result.is_signup_success,
          //   res.data.result.property
          // );
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.signupplease"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        self.signupData = {
          email: email,
          password: "",
          cfpassword: "",
          role: "provider",
        };
        self.gosignup();
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.signupplease"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    loginwithFacebook() {
      const self = this;
      var provider = new firebase.auth.FacebookAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;
          var user = result.user;
          var accessToken = credential.accessToken;

          const authenData = {
            email: user?.email || "",
            accessToken: accessToken || "",
          };
          self.facebookSignIn(authenData.accessToken, authenData.email);
        })
        .catch((error) => {
          swal(
            self.$t("Alert.error_title"),
            error.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        });
    },
    async facebookSignIn(accessToken, email) {
      const self = this;
      const obj = {
        access_token: accessToken,
      };
      const item = {
        signup_step: 0,
        username: email,
        password: "",
        type: "provider",
      };
      localStorage.clear();
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/login/facebook",
          obj
        );
        if (res.status == 200) {
          setTimeout(() => {
            localStorage.setItem("Token", res.data.result.access_token);
            localStorage.setItem("PropertyType", "");
            localStorage.setItem("PropertyNumber", "");
            localStorage.setItem("SignupStep", item.signup_step);
            localStorage.setItem("userEmail", item.username);
            self.$store.dispatch("changeSignupStep", {
              signupstep: item.signup_step,
            });
            self.$store.dispatch("changeSignupData", { signupData: item });
            self.$store.dispatch("responseSignupData", res.data.result);
            EventBus.$emit("endloading");
            self.initUserData(
              res.data.result.access_token,
              res.data.result.property[0].properties[0],
              res.data.result.is_first_login,
              res.data.result.is_signup_success,
              res.data.result.property,
              email
            );
            // self.gofillmore(
            //   res.data.result.access_token,
            //   res.data.result.property[0].properties[0],
            //   res.data.result.is_first_login,
            //   res.data.result.is_signup_success,
            //   res.data.result.property
            // );
          }, 2000);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.signupplease"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        self.signupData = {
          email: email,
          password: "",
          cfpassword: "",
        };
        self.gosignup();
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.signupplease"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    loginwithTwitter() {
      const self = this;
      var provider = new firebase.auth.TwitterAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          // The signed-in user info.
          var credential = result.credential;
          // console.log(result)
          var user = result.user;
          var accessToken = credential.accessToken;
          var accessSecret = credential.secret;

          const authenData = {
            email: user?.email || result?.additionalUserInfo?.profile?.email || "",
            accessToken: accessToken || "",
            accessSecret: accessSecret || "",
          };
          self.twitterSignIn(
            authenData.accessToken,
            authenData.accessSecret,
            authenData.email
          );
        })
        .catch((error) => {
          swal(
            self.$t("Alert.error_title"),
            error.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        });
    },
    async twitterSignIn(accessToken, accessSecret, email) {
      const self = this;
      const obj = {
        oauth_token: accessToken,
        oauth_token_secret: accessSecret,
        type: "provider",
      };
      var item = {
        signup_step: 0,
        username: email,
        password: "",
      };
      localStorage.clear();
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/login/twitter",
          obj
        );
        if (res?.data?.result?.email) {
          item = {
            signup_step: 0,
            username: email || res.data.result.email,
            password: "",
          };
        }
        if (res.status == 200) {
          setTimeout(() => {
            localStorage.setItem("Token", res.data.result.access_token);
            localStorage.setItem("PropertyType", "");
            localStorage.setItem("PropertyNumber", "");
            localStorage.setItem("SignupStep", item.signup_step);
            localStorage.setItem("userEmail", item.username);
            self.$store.dispatch("changeSignupStep", {
              signupstep: item.signup_step,
            });
            self.$store.dispatch("changeSignupData", { signupData: item });
            self.$store.dispatch("responseSignupData", res.data.result);
            EventBus.$emit("endloading");
            self.initUserData(
              res.data.result.access_token,
              res.data.result.property[0].properties[0],
              res.data.result.is_first_login,
              res.data.result.is_signup_success,
              res.data.result.property,
              email
            );
            // self.gofillmore(
            //   res.data.result.access_token,
            //   res.data.result.property[0].properties[0],
            //   res.data.result.is_first_login,
            //   res.data.result.is_signup_success,
            //   res.data.result.property
            // );
          }, 2000);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.signupplease"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        self.signupData = {
          email: email,
          password: "",
          cfpassword: "",
          role: "provider",
        };
        self.gosignup();
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.signupplease"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    async forgetPasswordFunc() {
      EventBus.$emit("loadingtillend");
      var linkItem = {
        link: "forget/password",
        name: "ForgetPasswordScreen",
      };
      EventBus.$emit("changePathname", linkItem);
      EventBus.$emit("endloading");
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-signupscreen {
  background-image: url(../../assets/images/aigent_bg_07062022.png);
}

.followus > a:hover {
  border: double 1px transparent;
  background-image: linear-gradient(#f9f9f9, #f9f9f9),
    radial-gradient(circle at top left, #f00, #3020ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.box-signup a:hover {
  color: var(--pink);
  text-decoration: underline;
}

.box-signup hr {
  border-color: var(--border-gray);
}

.form-control:focus {
  background-color: #fff;
  border-color: var(--pink);
  outline: 0;
  box-shadow: none !important;
}

.otp-input {
  width: 75px;
  height: 75px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 50%;
  border: 1px solid #bdbdbd;
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}

.otp-input:focus {
  border: 2px solid #ee3068;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

//  form-signup > inner > input-block > input-text > form-control > int1

.form-signup .inner {
  display: block;
  border-radius: 10px;
  border: 1px solid var(--border-gray);
  margin-bottom: 15px;
}

.form-signup .input-block {
  position: relative;
  display: block;
  text-align: left;
  padding: 0;
}

.form-signup .input-block:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-top: 1px solid var(--border-gray);
  pointer-events: none;
}

.form-signup .input-block:first-child:before {
  border-top: 1px solid transparent;
}

.form-signup .input-block.focus:before {
  border: 2px solid var(--pink);
  border-radius: 10px;
}

.form-signup .input-block.focus + .input-block:before {
  border-top: 1px solid transparent;
}

.form-signup .form-control {
  background-color: transparent;
  border-radius: 0;
  font-size: var(--f-16);
  font-weight: 400;
  height: 66px;
  padding: 40px 20px 20px;
  border-left: 0;
  border-right: 0;
  border: 0;
  box-shadow: none !important;
}

.form-control:focus {
  // background-color: #fff;
  border-color: var(--pink);
  outline: 0;
  box-shadow: none !important;
}

.field-icon {
  float: right;
  margin-left: -1.563rem;
  margin-top: -2.813rem;
  margin-right: 1.25rem;
  position: relative;
  z-index: 999;
}
</style>
