import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/plugins/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogged: false,
    user: {},
    token: '',
    language: '',
    pathname: '',
    signupstep: 1,
    signupData: {},
    resSignUpData: {},
    detailstep: 1
  },
  mutations: {
    LOGIN (state, payload) {
      state.isLogged = true
      state.user = payload
      state.token = payload.token
    },
    LOGOUT (state) {
      state.isLogged = false
      state.user = {}
      state.token = ''
    },
    LANGUAGE (state, payload) {
      state.language = payload
    },
    PATHNAME (state, payload) {
      state.pathname = payload.pathname
    },
    STEPSIGNUP (state, payload) {
      state.signupstep = payload.signupstep
    },
    STEPDETAIL (state, payload) {
      state.detailstep = payload.detailstep
    },
    SIGNUPDATA (state, payload) {
      state.signupData = payload.signupData
    },
    RESSIGNUPDATA (state, payload) {
      state.resSignUpData = payload
      state.token = payload.access_token
      localStorage.setItem('Token', payload.access_token)
    },
    CLEARSTOREDATA (state) {
      i18n.locale = state.language || 'en'
      localStorage.setItem('language', state.language || 'en')
      state = {
        isLogged: false,
        user: {},
        token: '',
        language: localStorage.getItem('language') || 'en',
        pathname: '',
        signupstep: 1,
        signupData: {},
        resSignUpData: {},
        detailstep: 1
      }
      localStorage.clear()
    }
  },
  actions: {
    loginUser ({ commit }, payload) {
      localStorage.setItem('isLogged', true)
      localStorage.setItem('Token', payload.token)
      commit('LOGIN', payload)
    },
    logoutUser ({ commit }) {
      localStorage.setItem('isLogged', false)
      localStorage.setItem('Token', '')
      commit('LOGOUT')
    },
    changeLG ({ commit }, payload) {
      i18n.locale = payload
      localStorage.setItem('language', payload)
      commit('LANGUAGE', payload)
    },
    changePathname ({ commit }, payload) {
      localStorage.setItem('pathname', payload.pathname)
      commit('PATHNAME', payload)
    },
    changeSignupStep ({ commit }, payload) {
      localStorage.setItem('signupstep', payload.signupstep)
      commit('STEPSIGNUP', payload)
    },
    changeSignupData ({ commit }, payload) {
      commit('SIGNUPDATA', payload)
    },
    responseSignupData ({ commit }, payload) {
      commit('RESSIGNUPDATA', payload)
    },
    clearStoreData ({ commit }) {
      commit('CLEARSTOREDATA')
    },
    changeDetailStep ({ commit }, payload) {
      localStorage.setItem('detailstep', payload.detailstep)
      commit('STEPDETAIL', payload)
    },
  },
  modules: {
  }
})
