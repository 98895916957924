export default {
  darkyellow: '#E5BC49',
  yellow: '#FBE49D',
  lightgrayselect: '#F2F2F2',
  gray: '#D4D4D4',
  darkestgray: '#0B0B0B',
  lightblue: '#5b73e8',
  darkpurple: '#5E348C',
  headpurple: '#262262',
  darkpink: '#81338A',
  pink: '#A03289',
  lightpink: '#EE3068',
  neonpink: '#C53188'
}
