<template lang="html">
  <v-app id="appbarsignin">
    <div class="page p-0">
      <header class="header has-menu header-slideout">
        <div class="container-fluid">
          <div class="navbar-brand" @click="gotoaigentFunc()">
            <img :src="require('@/assets/img/logo.svg')" />
          </div>

          <!-- <ul class="nav nav-main">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <li class="nav-lang">
                  <a v-bind="attrs" v-on="on"><img :src="selectedLanguage.img"></a>
                </li>
              </template>
              <v-list color="rgba(0,0,0,0.5)" dark>
                <v-list-item
                  v-for="(item, index) in itemsLanguage"
                  :key="index"
                >
                  <v-list-item-title class="change-language px-2" @click="changeLangFunc(item)"><img :src="item.img" class="mr-3">{{item.title}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </ul> -->
          <ul class="nav nav-general d-flex">
            <li @click="signoutFunc()">
              <a
                ><span>{{ $t("Navbar.exit") }}</span></a
              >
            </li>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <li class="nav-lang">
                  <a v-bind="attrs" v-on="on">
                    <img :src="selectedLanguage.img" />
                  </a>
                </li>
              </template>
              <v-list color="rgba(0,0,0,0.5)" dark>
                <v-list-item
                  v-for="(item, index) in itemsLanguage"
                  :key="index"
                >
                  <v-list-item-title
                    class="change-language px-2"
                    @click="changeLangFunc(item)"
                    ><img :src="item.img" class="mr-3" />{{
                      item.title
                    }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </ul>
        </div>
      </header>

      <div class="navbar-device">
        <div class="navbar-inner">
          <ul class="nav nav-main">
            <li v-for="item in links" :key="item.title" @click="gotolink(item)">
              <a
                ><span>{{ item.title }}</span></a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="page-slideout">
        <router-view />
      </div>
    </div>
    <v-overlay :value="overlay" color="white" :z-index="1100">
      <img
        :src="require('@/assets/img/thumb/loading.gif')"
        class="img-gif-loading"
      />
    </v-overlay>

    <v-dialog v-model="dialogExit" persistent max-width="360">
      <v-card>
        <v-card-title
          class="text-center"
          style="font-size: 1rem !important; letter-spacing: normal !important"
          >{{ $t("Alert.alertexitsignup") }}</v-card-title
        >
        <v-card-text class="text-center">{{
          $t("Alert.alertlabelexitsignup")
        }}</v-card-text>
        <v-card-actions class="justify-center align-center pb-5">
          <v-btn
            color="pink white--text"
            class="pa-3 mr-3"
            @click="confirmExit()"
            :disabled="!canclicknoexit"
            >{{ $t("Alert.btn_yes") }}</v-btn
          >
          <v-btn
            color="pink"
            class="pa-3"
            outlined
            @click="closeDialogExit"
            :disabled="!canclicknoexit"
            >{{ $t("Alert.btn_no") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import i18n from "@/plugins/i18n";
import { EventBus } from "@/EventBus";
import axios from "axios";

export default {
  data: () => ({
    overlay: false,
    oldpathname: window.location.pathname.replace("/", ""),
    pathname: window.location.pathname.replace("/", ""),
    drawer: false,
    group: null,
    item_signup: {
      title: "Sign Up",
      link: "signup",
      name: "SignupScreen",
      active: false,
    },
    links: [],
    itemsLanguage: [
      {
        title: "EN",
        name: "EN (UK)",
        value: "en",
        img: require("@/assets/thumb/flag-en.svg"),
      },
      {
        title: "ไทย",
        name: "TH",
        value: "th",
        img: require("@/assets/thumb/flag-th.svg"),
      },
      {
        title: "中国",
        name: "CN",
        value: "cn",
        img: require("@/assets/thumb/flag-cn.svg"),
      },
    ],
    selectedLanguage: {
      value: "en",
      img: require("@/assets/thumb/flag-en.svg"),
    },
    flagBtn: "",
    dialogExit: false,
    canclicknoexit: true,
    keepToken: "",
    userToken: "",
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    if (self.userToken) self.initUserData();
    localStorage.clear();
    self.initLinkItems();
    if (!self.$store.state.language && !localStorage.getItem("language"))
      self.getCountryIP();
    else self.initFlagsLanguage();
    self.overlay = true;
    setTimeout(() => {
      self.overlay = false;
    }, 1000);
  },
  watch: {
    pathname() {
      const self = this;
      // console.log(self.$store)
      self.links.map((el) => {
        if (el.link === self.pathname) {
          el.active = true;
        } else {
          el.active = false;
        }
      });
    },
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    const self = this;
    EventBus.$on("changePathname", self.gotolink);
    EventBus.$on("goBackPage", self.goBack);
    EventBus.$on("loadingPage", self.loadingPage);
    EventBus.$on("loadingtillend", self.loadingtillend);
    EventBus.$on("endloading", self.endloading);
  },
  computed: {
    flag() {
      return (this.flagBtn = i18n.locale);
    },
  },
  methods: {
    async getCountryIP() {
      const self = this;
      var ipct = "en";
      // try {
      //   const res = await self.axios.get("http://ip-api.com/json");
      //   if (res.status == 200) {
      //     if (res.data.country == "Thailand") {
      //       ipct = "th";
      //     } else if (res.data.country == "China") {
      //       ipct = "cn";
      //     } else ipct = "en";
      //     localStorage.setItem("ipcountry", ipct);
      //   } else localStorage.setItem("ipcountry", ipct);
      // } catch (error) {
      //   console.log(error?.response?.data?.message || error);
      // } finally {
      i18n.locale = ipct;
      localStorage.setItem("language", ipct);
      const tempSelectLG = self.itemsLanguage.find((el) => el.value == ipct);
      self.changeLangFunc(tempSelectLG);
      // }
    },
    initFlagsLanguage() {
      const self = this;
      if (self.$store.state.language) {
        i18n.locale = self.$store.state.language;
        localStorage.setItem("language", self.$store.state.language);
        const tempSelectLG = self.itemsLanguage.find(
          (el) => el.value == self.$store.state.language
        );
        self.changeLangFunc(tempSelectLG);
      } else if (localStorage.getItem("language")) {
        i18n.locale = localStorage.getItem("language");
        const tempSelectLG = self.itemsLanguage.find(
          (el) => el.value == localStorage.getItem("language")
        );
        self.changeLangFunc(tempSelectLG);
      } else {
        self.getCountryIP();
      }
      const temp = self.itemsLanguage.find(
        (el) => el.value == localStorage.getItem("language")
      );
      self.selectedLanguage = temp;
    },
    initLinkItems() {
      const self = this;
      self.links = [
        // {
        //   title: self.$t('Navbar.home'),
        //   link: 'home',
        //   name: 'HomeScreen',
        //   active: false
        // }, {
        //   title: self.$t('Navbar.features'),
        //   link: '',
        //   name: '',
        //   active: false
        // }, {
        //   title: self.$t('Navbar.ourteams'),
        //   link: '',
        //   name: '',
        //   active: false
        // }, {
        //   title: self.$t('Navbar.support'),
        //   link: '',
        //   name: '',
        //   active: false
        // }, {
        //   title: self.$t('Navbar.blog'),
        //   link: '',
        //   name: '',
        //   active: false
        // }, {
        //   title: self.$t('Navbar.signin'),
        //   link: 'signin',
        //   name: 'SigninScreen',
        //   active: false
        // },
        {
          title: self.$t("Navbar.signup"),
          link: "signup",
          name: "SignupScreen",
          active: false,
        },
      ];
    },
    changeLangFunc(item) {
      const self = this;
      self.$store.dispatch("changeLG", item.value);
      self.selectedLanguage = {
        value: item.value,
        img: item.img,
      };
      self.initLinkItems();
      self.loadingPage();
      EventBus.$emit("refreshRightPhoto");
    },
    async initUserData() {
      const self = this;
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/my_user", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") ||
              self?.$store?.state?.language,
          },
        });
        // console.log(res);
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        ).then(() => self.goexit());
      }
    },
    gotolink(item) {
      const self = this;
      self.$store.dispatch("changePathname", self.pathname);
      self.$router
        .push({ name: item.name, params: item.params })
        .catch(() => {});
    },
    goBack() {
      const self = this;
      self.overlay = true;
      setTimeout(() => {
        self.$router.go(-1);
        self.overlay = false;
      }, 200);
    },
    loadingPage() {
      const self = this;
      self.overlay = true;
      setTimeout(() => {
        self.overlay = false;
      }, 800);
    },
    loadingtillend() {
      const self = this;
      self.overlay = true;
    },
    endloading() {
      const self = this;
      self.overlay = false;
    },
    openDrawerbar() {
      // console.log('drawer bar')
      var element = document.getElementById("appbarsignin");
      element.classList.toggle("nav-opened");
      element.classList.add("closing");
    },
    gotoaigentFunc() {
      window.location = "https://aigentcorporation.com";
    },
    goexit() {
      const self = this;
      const item = { link: "signin", name: "SigninScreen" };
      self.$store.dispatch("clearStoreData");
      axios.CancelToken.source();
      setTimeout(() => {
        self.$router.push({ name: item.name }).catch(() => {});
        self.endloading();
      }, 2000);
    },
    signoutFunc() {
      const self = this;
      self.loadingtillend();
      const item = { link: "signin", name: "SigninScreen" };
      self.$store.dispatch("clearStoreData");
      setTimeout(() => {
        self.$router.push({ name: item.name }).catch(() => {});
        self.endloading();
      }, 2000);
      // const self = this;
      // self.keepToken = self.userToken
      // if (self.keepToken) {
      //   self.showDialogExit();
      // } else self.goexit();
    },
    showDialogExit() {
      const self = this;
      self.dialogExit = true;
    },
    closeDialogExit() {
      const self = this;
      self.dialogExit = false;
      self.endloading();
    },
    confirmExit() {
      const self = this;
      self.loadingtillend();
      self.canclicknoexit = false;
      self.dialogExit = false;
      self.goexit();
    },
  },
};
</script>

<style scoped>
.nav-main > li.nav-signup > a:hover {
  background-color: var(--pink);
  color: #fff;
  border-color: var(--pink);
}

@media (max-width: 991.98px) {
  .navbar-device {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    width: 300px;
    overflow: hidden;
    padding: 0;
    z-index: 1000;

    pointer-events: none;
    display: none;

    align-items: unset;
    -ms-flex-align: unset;
    background-color: #262262;
  }

  .nav-opened .navbar-device {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .closing .navbar-device {
    display: flex;
    pointer-events: auto;
  }
}
</style>
