//i18n.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '/src/assets/languages/en.json'
import th from '/src/assets/languages/th.json'
import cn from '/src/assets/languages/cn.json'
Vue.use(VueI18n)

const messages = {
  'en': en,
  'th': th,
  'cn': cn
}

const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'en',
  fallbackLocale: localStorage.getItem('language') || 'th',
  messages
})
export default i18n
