<template>
  <div class="section-h100">
    <div class="background bg-signupscreen"></div>
    <div class="container-fluid">
      <div
        class="box-signin animate__animated animate__slideInUp animate__delay-0.7s"
      >
        <span class="close-btn" @click="goToAnotherPage">X</span>
        <h2 class="box-header">{{ $t('Request Account') }}</h2>
        <div class="box-content">
          <form class="form form-signup">
            <div class="text-head">
              <span
                ><strong
                  >Enter your email address to request account</strong
                ></span
              >
            </div>
            <div class="inner">
              <div class="input-block" @click="getfocus('email')" id="email">
                <span class="input-text">{{ $t('Signup.email') }}</span>
                <input
                  type="text"
                  class="form-control int1"
                  v-model="userEmail"
                  @keydown.enter.prevent="showConfirmationPopup"
                />
              </div>
            </div>
            <div class="buttons">
              <button
                type="button"
                class="btn btn-lg w-100 gl-bt hover_btn"
                @click="showConfirmationPopup"
              >
                <span>{{ $t('SigninWeekend.dialog_confirm') }}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/EventBus';
import swal from 'sweetalert';
import firebase from 'firebase';

export default {
  name: 'ForgetPasswordScreen1',
  metaInfo: {
    title: 'Request Account',
    titleTemplate: '%s | Aigent',
  },
  data: () => ({
    loading: false,
    userEmail: '',
    showPassword: false,
    rules: {
      required: (value) => !!value || 'จำเป็น',
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'อีเมลไม่ถูกต้อง';
      },
    },
    refCode: '',
    value_element: '',
  }),
  created() {
    this.initSignup();
  },
  mounted() {
    this.colorHover();
    EventBus.$on('gotoSignin', this.initSignup);
  },
  methods: {
    colorHover() {
      const button = document.querySelector('.hover_btn');
      button.addEventListener('mousemove', (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty('--x', e.clientX - x);
        button.style.setProperty('--y', e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === '') {
        element.classList.add('focus');
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove('focus');
        element.classList.add('focus');
        this.value_element = val;
      }
    },
    initSignup() {
      const self = this;
      self.loading = true;
      setTimeout(() => (self.loading = false), 2000);
    },
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    gosignin() {
      var item = { link: 'signin', name: 'SigninScreen' };
      EventBus.$emit('changePathname', item);
    },
    gosignup() {
      var item = { link: 'signup', name: 'SignupScreen' };
      EventBus.$emit('changePathname', item);
    },
    gotogeneralterms() {
      window.open(
        `${window.location.origin || process.env.VUE_APP_WEB}/general`
      );
    },
    gotoserviceterms() {
      window.open(
        `${window.location.origin || process.env.VUE_APP_WEB}/privacy`
      );
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(String(email).toLowerCase());
    },
    async forgetPasswordFunc() {
      const self = this;
      EventBus.$emit('loadingtillend');
      if (self.userEmail) {
        if (!self.validateEmail(self.userEmail)) {
          EventBus.$emit('endloading');
          swal(
            self.$t('Alert.warn_title'),
            self.$t('Signup.invalidemail'),
            self.$t('Alert.warn_label'),
            {
              button: false,
              timer: 3000,
            }
          );
        } else {
          const obj = {
            username: self.userEmail.toLowerCase(),
          };
          try {
            const res = await self.axios.post(
              process.env.VUE_APP_API + '/reset_new_password',
              obj
            );
            if (res.status == 200) {
              EventBus.$emit('endloading');
              swal(
                self.$t('Alert.success_title'),
                self.$t('Signup.plscheckemailresetpass'),
                self.$t('Alert.success_label'),
                {
                  button: false,
                  timer: 3000,
                }
              ).then(() => {
                self.gosignin();
                self.userEmail = ''; // Clear the email input field after success
              });
            }
          } catch (error) {
            EventBus.$emit('endloading');
            swal(
              self.$t('Alert.error_title'),
              error?.response?.data?.message == 'user not found' ||
                error?.response?.data?.message == 'invalid value'
                ? self.$t('Alert.signupplease')
                : error?.response?.data?.message || self.$t('Alert.tryagain'),
              self.$t('Alert.error_label'),
              {
                button: false,
                timer: 3000,
              }
            ).then(() => {
              EventBus.$emit('endloading');
              if (
                error?.response?.data?.message == 'user not found' ||
                error?.response?.data?.message == 'invalid value'
              )
                self.gosignup();
              else self.gosignin();
            });
          }
        }
      } else {
        EventBus.$emit('endloading');
        swal(
          self.$t('Alert.warn_title'),
          self.$t('SigninUserAndRole.pls_fill_email'),
          self.$t('Alert.warn_label'),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    showConfirmationPopup() {
      const self = this;
      if (self.userEmail) {
        swal({
          title: 'Confirm account',
          text: 'Your account will be requested within 7 days',
          icon: 'warning',
          buttons: {
            confirm: {
              text: 'Confirm',
              value: true,
              visible: true,
              className: 'confirm-button',
            },
            cancel: {
              text: 'Cancel',
              value: null,
              visible: true,
              className: 'cancel-button',
            },
          },
          dangerMode: true,
          className: 'custom-swal',
        }).then((willDelete) => {
          if (willDelete) {
            self.forgetPasswordFunc().then(() => {
              swal({
                title: 'Success',
                text: ' ',
                icon: 'success',
                button: false,
                timer: 3000,
                className: 'success-swal',
                content: (
                  <img
                    src="path_to_your_tick_circle_icon.png"
                    alt="success-icon"
                    style={{
                      width: '120px',
                      height: '120px',
                      position: 'absolute',
                      left: '0px',
                      top: '0px',
                    }}
                  />
                ),
              }).then(() => {
                self.userEmail = ''; // Clear the email input field after success
              });
            });
          }
        });
      } else {
        swal(
          self.$t('Alert.warn_title'),
          self.$t('SigninUserAndRole.pls_fill_email'),
          self.$t('Alert.warn_label'),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    goToAnotherPage() {
      this.$router.push({ name: 'SignupScreen' }); // Replace 'SignupScreen' with the actual route name you want to redirect to
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-signupscreen {
  background-image: url(../../assets/images/aigent_bg_07062022.png);
}

.followus > a:hover {
  border: double 1px transparent;
  background-image: linear-gradient(#f9f9f9, #f9f9f9),
    radial-gradient(circle at top left, #f00, #3020ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.box-signup a:hover {
  color: var(--pink);
  text-decoration: none;
}

.box-signin {
  position: absolute;
  top: 20%;
  left: 31.5%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.box-header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.box-content {
  padding: 20px;
}

.text-head {
  text-align: left;
  margin-bottom: 1rem; /* Adjust margin if needed */
  padding-left: 10px; /* Align text with the input block */
}

.input-block {
  position: relative;
  display: block;
  text-align: left;
  padding: 0;
  padding-left: 1px; /* Adjust padding to align text properly */
}

.input-block:before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-top: 1px solid var(--border-gray);
  pointer-events: none;
}

.close-btn {
  position: absolute;
  top: 23px;
  right: 25px;
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
}

.btn:hover {
  background-color: #0056b3;
}

.custom-swal {
  position: relative;
  padding: 20px;
  text-align: center;
}

.success-swal {
  .swal-text {
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
  }
}
</style>
