<template>
  <div class="section-h100">
    <div class="background bg-signupscreen"></div>
    <div class="container-fluid">
      <div
        v-if="signup_step === 1"
        class="
          box-signup
          animate__animated animate__slideInUp animate__delay-0.7s
        "
      >
        <h2 class="box-header">{{ $t("Signup.title") }}</h2>
        <div class="box-content">
          <p>{{ $t("Signup.welcome") }}</p>
          <form class="form form-signup">
            <div class="inner">
              <div class="input-block" @click="getfocus('email')" id="email">
                <span class="input-text">{{ $t("Signup.email") }}</span>
                <input
                  type="text"
                  class="form-control int1"
                  v-model="signupData.email"
                  @keyup.enter="continuestep"
                />
              </div>
              <div class="input-block" @click="getfocus('pass')" id="pass">
                <span class="input-text">{{ $t("Signup.password") }}</span>
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control int2"
                  v-model="signupData.password"
                  @keyup.enter="continuestep"
                />
                <span class="icons icon-eye-close field-icon toggle-password">
                  <v-btn icon small @click="showPassword = !showPassword"
                    ><v-icon>{{
                      !showPassword ? "mdi-eye-off-outline" : "mdi-eye-outline"
                    }}</v-icon></v-btn
                  >
                </span>
              </div>
              <div
                class="input-block"
                @click="getfocus('comfirmpass')"
                id="comfirmpass"
              >
                <span class="input-text">{{ $t("Signup.cfpassword") }}</span>
                <input
                  :type="showCfPassword ? 'text' : 'password'"
                  class="form-control int3"
                  v-model="signupData.cfpassword"
                  @keyup.enter="continuestep"
                />
                <span class="icons icon-eye-close field-icon toggle-password">
                  <v-btn icon small @click="showCfPassword = !showCfPassword"
                    ><v-icon>{{
                      !showCfPassword
                        ? "mdi-eye-off-outline"
                        : "mdi-eye-outline"
                    }}</v-icon></v-btn
                  >
                </span>
              </div>
            </div>
            <div class="buttons">
              <button
                type="button"
                class="btn btn-lg w-100 gl-bt hover_btn"
                @click="continuestep"
                :disabled="!cancheckemail"
              >
                <span>{{ $t("Signup.continue") }}</span>
              </button>
            </div>
          </form>
          <div class="label-or">
            <span>{{ $t("Signup.or") }}</span>
          </div>
          <p>{{ $t("Signup.continuewith") }}</p>
          <div class="followus">
            <a @click="loginwithGoogle()">
              <img
                class="svg-js gg"
                :src="require('@/assets/icons/icon-googleplus.svg')"
                alt=""
              />
              <img
                class="gg"
                :src="require('@/assets/icons/icon-googleplus.svg')"
                alt=""
              />
            </a>
            <a @click="loginwithFacebook()">
              <img
                class="svg-js fb"
                :src="require('@/assets/icons/icon-facebook.svg')"
                alt=""
              />
              <img
                class="fb"
                :src="require('@/assets/icons/icon-facebook.svg')"
                alt=""
              />
            </a>
            <a @click="loginwithTwitter()">
              <img
                class="svg-js tw"
                :src="require('@/assets/icons/icon-twitter.svg')"
                alt=""
              />
              <img
                class="tw"
                :src="require('@/assets/icons/icon-twitter.svg')"
                alt=""
              />
            </a>
            <!-- <a @click="loginwithInstagram()">
              <img
                class="svg-js ig"
                :src="require('@/assets/icons/icon-instagram.svg')"
                alt=""
              />
              <img
                class="ig"
                :src="require('@/assets/icons/icon-instagram.svg')"
                alt=""
              />
            </a> -->
          </div>
          <p>
            {{ $t("Signup.registered") }}
            <a @click="gotologin">{{ $t("Signup.gotologin") }}</a>
          </p>
          <hr />
          <p class="m-0">
            {{ $t("Signup.policy1") }}{{ $t("Signup.createaccount") }}
            <br class="d-block d-md-none" />{{ $t("Signup.policy2") }}<br />
            <a @click="gotogeneralterms()">{{ $t("Signup.policy3") }}</a>
            {{ $t("Signup.policy4") }}
            <a @click="gotoserviceterms()">{{ $t("Signup.policy5") }}</a>
            {{ $t("Signup.policy6") }}
          </p>
          <hr />
          <p class="m-0 p-1 f12">{{ $t("Signup.sign") }}</p>
        </div>
      </div>
      <div
        v-if="signup_step === 2"
        class="
          mt-12
          box-signup
          mb-auto
          animate__animated animate__slideInUp animate__delay-0.7s
        "
      >
        <h2 class="box-header">{{ $t("Signup.confirmnumber") }}</h2>
        <div class="box-content">
          <p>
            {{ $t("Signup.sentotptoemail") }} :
            {{ signupData.email || $t("Signup.exampleemail") }}
          </p>
          <form class="form form-digit">
            <div class="inner">
              <v-otp-input
                ref="otpInput"
                separator=""
                inputClasses="form-control digit numbersOnly otp-input"
                :numInputs="4"
                length="4"
                :shouldAutoFocus="true"
                v-model="otp"
                :value="otp"
                :disabled="checkingOtp"
                @on-complete="handleOnComplete"
                @on-change="handleOnChange"
                @finish="handleOnComplete"
              />
            </div>
          </form>
          <p class="mb-4">{{ $t("Signup.refcode") }} : {{ refCode }}</p>
          <p class="mb-2">
            {{ $t("Signup.dontgetotp") }}
            <a @click="reOTP">{{ $t("Signup.tryagainsendotp") }}</a>
          </p>
        </div>
      </div>
    </div>
    <!-- <v-dialog
      v-model="openGoogle"
      max-width="290"
    >
      <v-card>
          <iframe id="inlineFrameExample"
            title="Inline Frame Example"
            width="300"
            height="200"
            src="https://aigentapp.getdev.top/api/v1/login/google">
          </iframe>
          </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";
import firebase from "firebase";
import { fi } from "date-fns/locale";
import OtpInput from "@bachdgvn/vue-otp-input";

export default {
  name: "SignupScreen",
  metaInfo: {
    title: "Aigent",
    titleTemplate: "%s",
  },
  components: {
    "v-otp-input": OtpInput,
  },
  data: () => ({
    loading: false,
    signup_step: 2,
    signupData: {
      email: "",
      password: "",
      cfpassword: "",
      role: "provider",
    },
    showPassword: false,
    showCfPassword: false,
    rules: {
      required: (value) => !!value,
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value);
      },
    },
    refCode: "",
    value_element: "",
    cancheckemail: true,
    srcGoogle: null,
    myIframe: null,
    openGoogle: false,
    loaded: false,
    iframe: {
      src: "",
      style: null,
      wrapperStyle: null,
    },
    otp: "",
    checkingOtp: false,
  }),
  created() {
    this.initSignup();
    // this.$store.dispatch('clearStoreData')
    this.rules = {
      required: (value) => !!value || this.$t("Signup.necessary"),
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || this.$t("Signup.wrongemail");
      },
    };
    if (localStorage.getItem("userEmail")) {
      this.signupData = {
        email: localStorage.getItem("userEmail"),
        password: "",
        cfpassword: "",
        role: "provider",
      };
    }
  },
  mounted() {
    this.colorHover();
    EventBus.$on("gotoSignin", this.initSignup);
  },
  computed: {},
  watch: {},
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      // console.log('ttt', document.querySelector('.hover_btn'))
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus");
        element.classList.add("focus");
        this.value_element = val;
      }
    },
    initSignup() {
      const self = this;
      self.loading = true;
      self.signup_step = 1;
      // console.log(self.$store.state)
      // self.$store.dispatch('clearStoreData')
      setTimeout(() => (self.loading = false), 2000);
    },
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    gotologin() {
      var item = { link: "signin", name: "SigninScreen" };
      EventBus.$emit("changePathname", item);
    },
    gotogeneralterms() {
      window.open(`${window.location.origin || process.env.VUE_APP_WEB}/general`);
    },
    gotoserviceterms() {
      window.open(`${window.location.origin || process.env.VUE_APP_WEB}/privacy`);
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(String(email).toLowerCase());
    },
    async continuestep() {
      const self = this;
      if (
        self.signupData.email &&
        self.signupData.password &&
        self.signupData.cfpassword
      ) {
        if (!self.validateEmail(self.signupData.email)) {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Signup.invalidemail"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } else if (
          self.signupData.password.length < 8 ||
          self.signupData.cfpassword.length < 8
        ) {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Signup.passmore"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } else if (self.signupData.password !== self.signupData.cfpassword) {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Signup.mismatchpass"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } else {
          EventBus.$emit("loadingtillend");
          self.cancheckemail = false;
          self.$store.dispatch("clearStoreData");
          try {
            const res = await self.axios.post(
              process.env.VUE_APP_API + "/check_signup",
              {
                email: self.signupData.email,
                password: self.signupData.password,
                role: "provider",
              }
            );
            if (res.status == 200) {
              EventBus.$emit("endloading");
              self.cancheckemail = true;
              self.refCode = res?.data?.result?.ref || "";
              self.signup_step = 2;
              self.$store.dispatch("changeSignupStep", {
                signupstep: self.signup_step,
              });
            } else {
              EventBus.$emit("endloading");
              swal(
                self.$t("Alert.warn_title"),
                self.$t("Signup.emailused"),
                self.$t("Alert.warn_label"),
                {
                  button: false,
                  timer: 3000,
                }
              );
            }
          } catch (error) {
            console.log(error?.response?.data?.message || error);
            EventBus.$emit("endloading");
            self.cancheckemail = true;
            swal(
              self.$t("Alert.warn_title"),
              error?.response?.data?.message || self.$t("Alert.tryagain"),
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          }
        }
      } else {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    async reOTP() {
      const self = this;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/check_signup",
          self.signupData
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          self.refCode = res.data.result.ref;
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            res?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        EventBus.$emit("endloading");
      }
    },
    handleOnChange(value) {
      const self = this;
      self.otp = value;
      // if (self.otp?.length > 3 || self.otp?.length == 4)
      //   self.handleOnComplete();
    },
    async handleOnComplete(value) {
      const self = this;
      if (value?.length > 3 || value?.length == 4) self.otp = value;
      const item = {
        signup_step: self.signup_step,
        email: self.signupData.email,
        password: self.signupData.password,
        role: "provider",
        otp: value || self.otp,
        refCode: self.refCode,
      };

      // console.log("otp:", value, "otppp:", self.otp);
      EventBus.$emit("loadingtillend");

      self.checkingOtp = true;

      if (item.otp?.length == 4) {
        try {
          const res = await self.axios.post(
            process.env.VUE_APP_API + "/signup",
            item
          );
          if (res.status == 200) {
            self.checkingOtp = false;
            self.signup_step = 0;
            localStorage.setItem("Token", res.data.result.access_token);
            localStorage.setItem("PropertyType", "");
            localStorage.setItem("PropertyNumber", "");
            localStorage.setItem("SignupStep", self.signup_step);
            localStorage.setItem("userEmail", item.email);
            self.$store.dispatch("changeSignupStep", {
              signupstep: self.signup_step,
            });
            self.$store.dispatch("changeSignupData", { signupData: item });
            self.$store.dispatch("responseSignupData", res.data.result);
            self.gofillmore(res.data.result.access_token);
          } else {
            self.checkingOtp = false;
            EventBus.$emit("endloading");
            swal(
              self.$t("Alert.warn_title"),
              res.data.message || self.$t("Alert.tryagain"),
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          }
        } catch (error) {
          self.checkingOtp = false;
          console.log(error);
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            error?.response?.data?.message ||
              error ||
              self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } else {
        self.checkingOtp = false;
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Signup.wrongOTP"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    gofillmore(token) {
      var item = {
        link: `signup/propertytype/${token}`,
        name: "SignupPropertyTypeScreen",
        params: { userToken: token },
      };
      EventBus.$emit("changePathname", item);
    },
    loginwithGoogle() {
      const self = this;
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(
          (user) => {
            // console.log('provider: ', provider, 'user: ', user)
            const authenData = {
              email: user?.additionalUserInfo?.profile?.email || "",
              accessToken: user?.credential?.accessToken || "",
            };
            self.googleSignIn(authenData.accessToken, authenData.email);
          },
          (err) => {
            swal(
              self.$t("Alert.error_title"),
              err.message || self.$t("Alert.tryagain"),
              self.$t("Alert.error_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          }
        );
    },
    async googleSignIn(accessToken, email) {
      const self = this;
      const obj = {
        access_token: accessToken,
        type: "provider",
      };
      const item = {
        signup_step: 0,
        username: email,
        password: "",
      };
      localStorage.clear();
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/login/google",
          obj
        );
        // console.log(res)
        if (res.status == 200) {
          setTimeout(() => {
            localStorage.setItem("Token", res.data.result.access_token);
            localStorage.setItem("PropertyType", "");
            localStorage.setItem("PropertyNumber", "");
            localStorage.setItem("SignupStep", item.signup_step);
            localStorage.setItem("userEmail", item.username);
            self.$store.dispatch("changeSignupStep", {
              signupstep: item.signup_step,
            });
            self.$store.dispatch("changeSignupData", { signupData: item });
            self.$store.dispatch("responseSignupData", res.data.result);
            EventBus.$emit("endloading");
            self.gofillmore(res.data.result.access_token);
          }, 2000);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.signupplease"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        self.signupData = {
          email: email,
          password: "",
          cfpassword: "",
          role: "provider",
        };
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.signupplease"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    onLoad(frame) {
      this.myIframe = frame.contentWindow;
    },
    loginwithFacebook() {
      const self = this;
      var provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope("email");
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;
          var user = result.user;
          var accessToken = credential.accessToken;
          var additionalUserInfo = result.additionalUserInfo;

          const authenData = {
            email: user?.email || additionalUserInfo?.profile?.email || "",
            accessToken: accessToken || "",
          };
          // console.log('provider: ', provider, 'user: ', user, 'result: ', result)
          // console.log('authenData: ', authenData)
          self.facebookSignIn(authenData.accessToken, authenData.email);
        })
        .catch((error) => {
          swal(
            self.$t("Alert.error_title"),
            error.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        });
    },
    async facebookSignIn(accessToken, email) {
      const self = this;
      const obj = {
        access_token: accessToken,
        type: "provider",
      };
      const item = {
        signup_step: 0,
        username: email,
        password: "",
      };
      localStorage.clear();
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/login/facebook",
          obj
        );
        if (res.status == 200) {
          setTimeout(() => {
            localStorage.setItem("Token", res.data.result.access_token);
            localStorage.setItem("PropertyType", "");
            localStorage.setItem("PropertyNumber", "");
            localStorage.setItem("SignupStep", item.signup_step);
            localStorage.setItem("userEmail", item.username);
            self.$store.dispatch("changeSignupStep", {
              signupstep: item.signup_step,
            });
            self.$store.dispatch("changeSignupData", { signupData: item });
            self.$store.dispatch("responseSignupData", res.data.result);
            EventBus.$emit("endloading");
            self.gofillmore(res.data.result.access_token);
          }, 2000);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.signupplease"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        self.signupData = {
          email: email,
          password: "",
          cfpassword: "",
          role: "provider",
        };
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.signupplease"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    loginwithTwitter() {
      const self = this;
      var provider = new firebase.auth.TwitterAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          // The signed-in user info.
          var credential = result.credential;
          // console.log(result)
          var user = result.user;
          var accessToken = credential.accessToken;
          var accessSecret = credential.secret;

          const authenData = {
            email:
              user?.email || result?.additionalUserInfo?.profile?.email || "",
            accessToken: accessToken || "",
            accessSecret: accessSecret || "",
          };
          self.twitterSignIn(
            authenData.accessToken,
            authenData.accessSecret,
            authenData.email
          );
        })
        .catch((error) => {
          swal(
            self.$t("Alert.error_title"),
            error.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        });
    },
    async twitterSignIn(accessToken, accessSecret, email) {
      const self = this;
      const obj = {
        oauth_token: accessToken,
        oauth_token_secret: accessSecret,
        type: "provider",
      };
      var item = {
        signup_step: 0,
        username: email,
        password: "",
      };
      localStorage.clear();
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + "/login/twitter",
          obj
        );
        if (res?.data?.result?.email) {
          item = {
            signup_step: 0,
            username: email || res.data.result.email,
            password: "",
          };
        }
        if (res.status == 200) {
          setTimeout(() => {
            localStorage.setItem("Token", res.data.result.access_token);
            localStorage.setItem("PropertyType", "");
            localStorage.setItem("PropertyNumber", "");
            localStorage.setItem("SignupStep", item.signup_step);
            localStorage.setItem("userEmail", item.username);
            self.$store.dispatch("changeSignupStep", {
              signupstep: item.signup_step,
            });
            self.$store.dispatch("changeSignupData", { signupData: item });
            self.$store.dispatch("responseSignupData", res.data.result);
            EventBus.$emit("endloading");
            self.gofillmore(res.data.result.access_token);
          }, 2000);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.signupplease"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        self.signupData = {
          email: email,
          password: "",
          cfpassword: "",
          role: "provider",
        };
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.signupplease"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    loginwithInstagram() {
      const self = this;
      const provider = "instagram";
      this.$auth
        .authenticate(provider)
        .then((response) => {
          self.SocialLogin(provider, response);
        })
        .catch((err) => {
          console.log({ err: err });
        });
    },
    SocialLogin(provider, response) {
      this.$http
        .post("/sociallogin/" + provider, response)
        .then((response) => {
          // console.log(response.data)
        })
        .catch((err) => {
          console.log({ err: err });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-signupscreen {
  background-image: url(../../../assets/images/aigent_bg_07062022.png);
}

.followus > a:hover {
  border: double 1px transparent;
  background-image: linear-gradient(#f9f9f9, #f9f9f9),
    radial-gradient(circle at top left, #f00, #3020ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.box-signup a:hover {
  color: var(--pink);
  text-decoration: underline;
}

.box-signup hr {
  border-color: var(--border-gray);
}

.form-control:focus {
  background-color: #fff;
  border-color: var(--pink);
  outline: 0;
  box-shadow: none !important;
}

.v-otp-input .v-input {
  margin: 0;
  flex: 1 0 32px;
}

.otp-input {
  width: 4.688rem !important;
  height: 4.688rem !important;
  padding: 0.313rem !important;
  margin: 0 0.625rem !important;
  font-size: 1.25rem !important;
  border-radius: 50% !important;
  border: 1px solid #bdbdbd !important;
  text-align: center !important;
  &.error {
    border: 1px solid red !important;
  }
}

.otp-input:focus {
  border: 2px solid #ee3068 !important;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error {
  border: 1px solid red !important;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

//  form-signup > inner > input-block > input-text > form-control > int1

.form-signup .inner {
  display: block;
  border-radius: 10px;
  border: 1px solid var(--border-gray);
  margin-bottom: 15px;
}

.form-signup .input-block {
  position: relative;
  display: block;
  text-align: left;
  padding: 0;
}

.form-signup .input-block:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-top: 1px solid var(--border-gray);
  pointer-events: none;
}

.form-signup .input-block:first-child:before {
  border-top: 1px solid transparent;
}

.form-signup .input-block.focus:before {
  border: 2px solid var(--pink);
  border-radius: 10px;
}

.form-signup .input-block.focus + .input-block:before {
  border-top: 1px solid transparent;
}

.form-signup .form-control {
  background-color: transparent;
  border-radius: 0;
  font-size: var(--f-16);
  font-weight: 400;
  height: 66px;
  padding: 40px 20px 20px;
  border-left: 0;
  border-right: 0;
  border: 0;
  box-shadow: none !important;
}

.form-control:focus {
  // background-color: #fff;
  border-color: var(--pink);
  outline: 0;
  box-shadow: none !important;
}

.field-icon {
  float: right;
  margin-left: -1.563rem;
  margin-top: -2.813rem;
  margin-right: 1.25rem;
  position: relative;
  z-index: 999;
}

iframe {
  border: 1px solid black;
  width: 100%;
}

.output {
  background: #eee;
}
</style>

<style>
.v-otp-input .v-input .v-input__control .v-input__slot {
  width: 4.688rem !important;
  height: 4.688rem !important;
  padding: 0.313rem !important;
  margin: 0 0.625rem !important;
  font-size: 1.25rem !important;
  border-radius: 50% !important;
}
</style>
