import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from '../constants/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.darkpurple,
        secondary: colors.pink,
        darkpurple: colors.darkpurple,
        pink: colors.lightpink,
        gray: colors.gray,
        headpurple: colors.headpurple,
        darkestgray: colors.darkestgray,
        lightgrayselect: colors.lightgrayselect
      },
      dark: {
        primary: colors.darkpurple,
        secondary: colors.pink,
        darkpurple: colors.darkpurple,
        pink: colors.lightpink,
        gray: colors.gray,
        headpurple: colors.headpurple,
        darkestgray: colors.darkestgray,
        lightgrayselect: colors.lightgrayselect
      }
    }
  }
});
