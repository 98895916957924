import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import router from './router'
import store from './store'
import OtpInput from '@bachdgvn/vue-otp-input'
import VueTelInput from 'vue-tel-input'
// import 'vue-tel-input/dist/vue-tel-input.css'

import * as VueGoogleMaps from 'vue2-google-maps'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
import VueIframe from 'vue-iframes'
import firebase from 'firebase/app'
import VueSocialauth from 'vue-social-auth'
import VueDndZone from 'vue-dnd-zone'
import VueHtmlToPaper from 'vue-html-to-paper'
import 'vue-dnd-zone/vue-dnd-zone.css'
// import './js/jquery-3.4.1.min'
// import './js/popper.min.js'
// import './js/bootstrap.min'
import './js/ie11CustomProperties'
import './js/wow'
import './js/swiper'
import './js/jquery.fancybox'
import './js/jquery.bootstrap-touchspin'
// import './js/jquery.scrollbar'
// import './js/bootstrap-select'
// import './js/custom'

Vue.config.productionTip = false
Vue.component("v-otp-input", OtpInput)

Vue.use(VueTelInput)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_APIKEY,
    libraries: 'places'
  }
})
Vue.use(VueAxios, axios)
Vue.use(VueMeta)
Vue.use(VueIframe)
Vue.use(VueDndZone)

const optionsHtmlToPaper = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    // 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    './styles/bootstrap/global.css'
  ],
  timeout: 1000,
  autoClose: true,
  windowTitle: ''
  // windowTitle: window.document.title,
}

Vue.use(VueHtmlToPaper, optionsHtmlToPaper);

var firebaseConfig = {
  apiKey: "AIzaSyBgZQJNEBRxXk4HCkt3mQ-V4XiDG51KSS0",
  authDomain: "aigent-74599.firebaseapp.com",
  projectId: "aigent-74599",
  storageBucket: "aigent-74599.appspot.com",
  messagingSenderId: "394244193213",
  appId: "1:394244193213:web:283d9d872b871a6f7a30d4",
  measurementId: "G-EXGTK0YJ70"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.use(VueSocialauth, {

  providers: {
    instagram: {
      clientId: '306383280963379',
      redirectUri: '/auth/instagram/callback' // Your client app URL
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  VueTelInput,
  render: h => h(App)
}).$mount('#app')
