<template>
  <v-app id="appbarprovider" style="background: #f4f5fa">
    <v-navigation-drawer
      app
      dark
      permanent
      v-model="drawer"
      width="300"
      :mini-variant.sync="mini"
      color="#0a1a2a"
    >
      <div class="main-menu-content" id="main-menu-navigation">
        <v-list link class="navigation navigation-main">
          <div style="height: 80px" class="d-flex">
            <v-list-item
              class="nav-item mb-2"
              color="#08121e"
              @click="gotopropmanage()"
            >
              <img
                :src="require('@/assets/images/icons/Logo-07.png')"
                width="35"
              />
              <img
                :src="require('@/assets/images/icons/Logo-01.png')"
                class="ml-4"
                width="120"
              />
            </v-list-item>
          </div>
          <div v-for="item in itemsNavList" :key="item._id">
            <div v-if="mini === false">
              <v-list-item
                v-if="!item.submenu"
                link
                :class="[
                  currentPage.includes(item.link)
                    ? 'activeClass'
                    : 'inactiveClass',
                ]"
                class="nav-item mb-2"
                @click="gotolink(item)"
              >
                <v-icon>{{ item.icon }}</v-icon>
                <span class="menu-title ml-3">{{ item.name }}</span>
              </v-list-item>
              <div v-else-if="item.submenu">
                <v-list-group
                  link
                  class="nav-item"
                  :class="[
                    currentPage.includes(item.link)
                      ? 'activeClass'
                      : 'inactiveClass',
                  ]"
                  color="white"
                  :value="listGroup"
                >
                  <template v-slot:activator>
                    <v-icon>{{ item.icon }}</v-icon>
                    <span class="menu-title ml-3">{{ item.name }}</span>
                    <v-list-item-content></v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="child in item.submenu"
                    :key="child._id"
                    @click="gotolink(child)"
                  >
                    <span class="menu-title ml-3">{{ child.name }}</span>
                  </v-list-item>
                </v-list-group>
              </div>
            </div>
            <div v-else>
              <v-menu
                :open-on-hover="true"
                :close-on-content-click="false"
                :nudge-width="180"
                class="mt-1"
                color="#2C343A"
                tile
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    link
                    v-bind="attrs"
                    v-on="on"
                    class="nav-item mb-2"
                    :class="[
                      currentPage.includes(item.link)
                        ? 'activeClass'
                        : 'inactiveClass',
                    ]"
                    @click="gotolink(item)"
                  >
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item>
                </template>
                <div class="menu-list">
                  <div v-if="item.submenu">
                    <div class="header-list pa-2">
                      <span class="menu-title ml-3">{{ item.name }}</span>
                    </div>
                    <div
                      v-for="child in item.submenu"
                      :key="child._id"
                      @click="gotolink(child)"
                      class="sub-list pa-2"
                    >
                      <span class="span-sub-list">{{ child.name }}</span>
                    </div>
                  </div>
                  <div
                    v-else-if="!item.submenu"
                    @click="gotolink(item)"
                    class="header-list pa-2"
                  >
                    <span class="menu-title ml-3">{{ item.name }}</span>
                  </div>
                </div>
              </v-menu>
            </div>
          </div>
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-app-bar app height="80px" color="white">
      <v-row row wrap class="px-4 align-center">
        <v-btn icon small>
          <v-icon color="#6b6f82" @click="stopmini">mdi-menu</v-icon>
        </v-btn>
        <v-btn icon small class="ml-6">
          <v-icon color="#6b6f82">mdi-magnify</v-icon>
        </v-btn>
        <v-spacer />
        <div class="appbar-menu">
          <span class="label text-dark">
            {{ checkPropertyNameFunc() || propertyName }}
          </span>
          <!-- <v-menu v-model="showPropertyMenu" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <span class="label" v-bind="attrs" v-on="on">
                {{ propertyName }}</span
              >
            </template>
            <v-list>
              <v-list-item-group
                v-model="propertyID"
                color="primary"
                v-for="item in itemsPropertyOfUser"
                :key="item._id"
              >
                <v-list-item link @click="changePropertyData(item._id)">
                  <v-list-item-title
                    @click="changePropertyData(item._id)"
                    v-text="item.name"
                  ></v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>-->
        </div>
        <v-spacer />
        <ul class="nav d-flex flex-row align-center">
          <v-menu v-model="showUserMenu" left offset-y :nudge-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                bordered
                bottom
                dot
                color="#28d094"
                offset-x="10"
                offset-y="10"
              >
                <v-avatar size="40" v-bind="attrs" v-on="on">
                  <img
                    :src="
                      coverImage
                        ? coverImage
                        : require('@/assets/img/thumb/placeholder-image.png')
                    "
                    alt
                  />
                </v-avatar>
              </v-badge>
            </template>
            <v-list>
              <v-list-item>
                <v-row row wrap>
                  <v-col cols="12" align="center" class="mt-3">
                    <p
                      @click="showUserMenu = false"
                      style="
                        margin-bottom: -15px;
                        float: right;
                        cursor: pointer;
                      "
                    >
                      <v-icon small color="#6b6f82">mdi-window-close</v-icon>
                    </p>
                    <v-badge
                      bordered
                      bottom
                      dot
                      color="#28d094"
                      offset-x="10"
                      offset-y="10"
                    >
                      <v-avatar size="50">
                        <img
                          alt
                          :src="
                            coverImage
                              ? coverImage
                              : require('@/assets/img/thumb/placeholder-image.png')
                          "
                        />
                      </v-avatar>
                    </v-badge>

                    <p style="font-size: 13px; margin: 10px 0 5px 0">
                      {{ userEmail }}
                    </p>
                    <div class="d-flex justify-content-center">
                      <a
                        class="btn btn-dark btn-block mt-1"
                        style="
                          width: 7.5rem !important;
                          height: 2.625rem !important;
                          color: #fff !important;
                        "
                        @click="goexit()"
                        >{{ $t("SigninToolbar.signout") }}</a
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-divider
                v-if="propertyData.voucher_suspend == false"
                class="mb-2"
              ></v-divider>
              <div
                v-if="propertyData.voucher_suspend == false"
                class="user-menu pa-2"
              >
                <p
                  v-if="checkRoleUserFunc() == 'provider'"
                  @click="gotopropmanage()"
                >
                  <v-icon left small>mdi-plus-circle-outline</v-icon>
                  <span class="mt-1">
                    {{ $t("SigninToolbar.add_new_property") }}
                  </span>
                </p>
                <p @click="gotoinviteuserFunc()">
                  <v-icon left small>mdi-plus-circle-outline</v-icon>
                  <span class="mt-1">
                    {{ $t("SigninToolbar.invite_new_contact") }}
                  </span>
                </p>
                <p @click="gotopropmanage()" class="mb-0">
                  <v-icon left small>mdi-swap-horizontal</v-icon>
                  <span>{{ $t("SigninToolbar.switch_property") }}</span>
                </p>
              </div>
            </v-list>
          </v-menu>

          <v-menu offset-y class="menu-language">
            <template v-slot:activator="{ on, attrs }">
              <li class="nav-lang ml-6">
                <a v-bind="attrs" v-on="on">
                  <img :src="selectedLanguage.img" />
                </a>
              </li>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in itemsLanguage" :key="index">
                <v-list-item-title
                  class="change-language px-2"
                  @click="changeLangFunc(item)"
                >
                  <img :src="item.img" class="mr-3" />
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu
            v-if="propertyData.voucher_suspend == false"
            offset-y
            class="menu-language"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                :value="remainDayOut"
                bordered
                overlap
                dot
                color="error"
                class="ml-6"
              >
                <v-icon color="#6b6f82" v-bind="attrs" v-on="on"
                  >mdi-bell-outline</v-icon
                >
              </v-badge>
            </template>
            <v-list>
              <v-list-item v-if="remainDayOut">
                <v-row row wrap>
                  <v-col
                    cols="12"
                    class="my-2 alert-team-purchase"
                    @click="gotoTeamPurchaseFunc()"
                  >
                    <span>
                      <v-icon x-small class="mr-2" color="error"
                        >mdi-alert-circle</v-icon
                      >
                      <span class="text-dark mr-1">{{
                        $t("SigninTeamPurchase.title")
                      }}</span>
                      <span class="text-bold text-error">{{
                        $t("SigninTeamPurchase.expired_campaign")
                      }}</span>
                    </span>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item v-else>
                <v-row row wrap>
                  <v-col cols="12">
                    <span class="text-gray">{{
                      $t("RateAndInventory.nodata")
                    }}</span>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-menu>
        </ul>

        <v-btn
          v-if="propertyData.voucher_suspend == false"
          icon
          small
          class="ml-6"
        >
          <v-icon color="#6b6f82">mdi-email-outline</v-icon>
        </v-btn>
      </v-row>
    </v-app-bar>
    <v-main class="position-vmain">
      <router-view></router-view>
    </v-main>
    <!-- <v-overlay :value="overlay" color="white" :z-index="1100">
      <img
        :src="require('@/assets/img/thumb/loading.gif')"
        class="img-gif-loading"
      />
    </v-overlay>
    <v-overlay :value="loadingData" color="white" :z-index="1100">
      <img
        :src="require('@/assets/img/thumb/loading.gif')"
        class="img-gif-loading"
      />
    </v-overlay>-->
    <v-overlay :value="inProgress" color="white" :z-index="1100">
      <img
        :src="require('@/assets/img/thumb/loading.gif')"
        class="img-gif-loading"
      />
    </v-overlay>
  </v-app>
</template>

<script>
import i18n from "@/plugins/i18n";
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import axios from "axios";
import moment from "moment";

export default {
  data: () => ({
    host: location.host,
    drawer: true,
    mini: false,
    listGroup: false,
    overlay: false,
    loadingData: false,
    group: null,
    itemsLanguage: [
      {
        title: "EN",
        name: "EN (UK)",
        value: "en",
        img: require("@/assets/thumb/flag-en.svg"),
      },
      {
        title: "ไทย",
        name: "TH",
        value: "th",
        img: require("@/assets/thumb/flag-th.svg"),
      },
      {
        title: "中国",
        name: "CN",
        value: "cn",
        img: require("@/assets/thumb/flag-cn.svg"),
      },
    ],
    selectedLanguage: {
      value: "en",
      img: require("@/assets/thumb/flag-en.svg"),
    },
    flagBtn: "",
    keepToken: "",
    userToken: "",
    userEmail: "",
    propertyID: "",
    dialogExit: false,
    canclicknoexit: true,
    exitEvent: true,
    propertyData: {},
    initStartStep: 0,
    itemsNavList: [],
    menu: [],
    itemsPropertyOfUser: [],
    propertyName: "",
    coverImage: "",
    showUserMenu: false,
    itemsUser: [],
    userData: null,
    showPropertyMenu: false,
    inProgress: false,
    remainDayOut: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    if (!self.$store.state.language && !localStorage.getItem("language"))
      self.getCountryIP();
    else self.initFlagsLanguage();
    self.initUserData();
  },
  watch: {
    group() {
      this.drawer = false;
    },
    propertyName() {
      const self = this;
      if (!self.propertyName) {
        self.loadingtillend();
      } else {
        self.endloading();
      }
    },
  },
  mounted() {
    EventBus.$on("changePathname", this.gotofillmoredata);
    EventBus.$on("refreshAppbarProperty", this.initUserData);
    EventBus.$on("goBackPage", this.goBack);
    EventBus.$on("loadingPage", this.loadingPage);
    EventBus.$on("loadingtillend", this.loadingtillend);
    EventBus.$on("endloading", this.endloading);
    EventBus.$on("startLoadingFunc", this.startLoadingFunc);
    EventBus.$on("endLoadingFunc", this.endLoadingFunc);
    EventBus.$on("goSignout", this.signoutFunc);
    EventBus.$on("inProgressing", this.inProgressFunc);
    EventBus.$on("endProgress", this.endProgressFunc);
  },
  computed: {
    flag() {
      return (this.flagBtn = i18n.locale);
    },
    currentPage() {
      return this.$route.path;
    },
  },
  methods: {
    stopmini() {
      this.mini = !this.mini;
      if (this.mini === true) this.listGroup = false;
    },
    confirmDialog() {
      // console.log("close");
    },
    gotopropmanage() {
      const self = this;
      self.$router
        .push({
          name: "SigninPropertyManagementScreen",
          params: {
            userToken: self?.userToken,
            propertyId: self?.propertyID,
          },
        })
        .catch(() => {});
    },
    gotouserFunc() {
      const self = this;
      self.$router
        .push({
          name: "SigninUserScreen",
          params: {
            userToken: self?.userToken,
            propertyId: self?.propertyID,
          },
        })
        .catch(() => {});
    },
    gotoinviteuserFunc() {
      const self = this;
      self.$router
        .push({
          name: "SigninInviteUserScreen",
          params: {
            userToken: self?.userToken,
            propertyId: self?.propertyID,
          },
        })
        .catch(() => {});
    },
    gotolink(item) {
      const self = this;
      // console.log("item: ", item);
      self.$router
        .push({
          name: item.screen,
          params: {
            userToken: self?.userToken,
            propertyId: self?.propertyID,
          },
        })
        .catch(() => {});
    },
    async getCountryIP() {
      const self = this;
      var ipct = "en";
      // try {
      //   const res = await self.axios.get("http://ip-api.com/json");
      //   if (res.status == 200) {
      //     if (res.data.country == "Thailand") {
      //       ipct = "th";
      //     } else if (res.data.country == "China") {
      //       ipct = "cn";
      //     } else ipct = "en";
      //     localStorage.setItem("ipcountry", ipct);
      //   } else localStorage.setItem("ipcountry", ipct);
      // } catch (error) {
      //   console.log(error?.response?.data?.message || error);
      // } finally {
      i18n.locale = ipct;
      localStorage.setItem("language", ipct);
      const tempSelectLG = self.itemsLanguage.find((el) => el.value == ipct);
      self.changeLangFunc(tempSelectLG);
      // }
    },
    initFlagsLanguage() {
      const self = this;
      if (self.$store.state.language) {
        i18n.locale = self.$store.state.language;
        localStorage.setItem("language", self.$store.state.language);
        const tempSelectLG = self.itemsLanguage.find(
          (el) => el.value == self.$store.state.language
        );
        self.changeLangFunc(tempSelectLG);
      } else if (localStorage.getItem("language")) {
        i18n.locale = localStorage.getItem("language");
        const tempSelectLG = self.itemsLanguage.find(
          (el) => el.value == localStorage.getItem("language")
        );
        self.changeLangFunc(tempSelectLG);
      } else {
        self.getCountryIP();
      }
      const temp = self.itemsLanguage.find(
        (el) => el.value == localStorage.getItem("language")
      );
      self.selectedLanguage = temp;
    },
    changeLangFunc(item) {
      const self = this;
      self.$store.dispatch("changeLG", item.value);
      // self.$store.dispatch("language", item.value);
      i18n.locale = item.value;
      localStorage.setItem("language", item.value);
      self.selectedLanguage = {
        value: item.value,
        img: item.img,
      };
      self.initStartStep += 1;
      if (self?.propertyID) {
        self.itemsNavList = [];
        self.itemsUser = [];
        self.initUserData();
      }
      // console.log("lang: ", self.$store.state.language);
      EventBus.$emit("refreshRightPhoto");
    },
    gotofillmoredata(item) {
      const self = this;
      var itemspathname;
      if (item?.params?.propertyId) {
        itemspathname = {
          ...item.params,
          userToken: self?.userToken,
        };
      } else if (self?.propertyID) {
        itemspathname = {
          ...item.params,
          propertyId: self?.propertyID,
          userToken: self?.userToken,
        };
      } else {
        itemspathname = {
          ...item.params,
          userToken: self?.userToken,
        };
      }
      self.initPropertyData();
      self.$router
        .push({ name: item.name, params: itemspathname })
        .catch(() => {});
    },
    goexit() {
      const self = this;
      self.inProgressFunc();
      self.loadingtillend();
      const item = { link: "signin", name: "SigninScreen" };
      self.$store.dispatch("clearStoreData");
      axios.CancelToken.source();
      setTimeout(() => {
        self.$router.push({ name: item.name }).catch(() => {});
        self.endloading();
        self.endProgressFunc();
      }, 2000);
    },
    signoutFunc() {
      const self = this;
      self.keepToken =
        localStorage.getItem("Token") ||
        self.$store.state.resSignUpData.access_token;
      if (self.keepToken) {
        self.showDialogExit();
      } else self.goexit();
    },
    confirmExit() {
      const self = this;
      self.loadingtillend();
      self.canclicknoexit = false;
      self.dialogExit = false;
      self.goexit();
    },
    goBack() {
      const self = this;
      self.overlay = true;
      setTimeout(() => {
        self.$router.go(-1);
        self.overlay = false;
      }, 200);
    },
    loadingPage() {
      // const self = this
      // self.overlay = true
      // setTimeout(() => {
      //   self.overlay = false
      // }, 800)
    },
    loadingtillend() {
      const self = this;
      // self.overlay = true;
    },
    endloading() {
      const self = this;
      self.overlay = false;
    },
    startLoadingFunc() {
      this.loadingData = true;
    },
    endLoadingFunc() {
      this.loadingData = false;
    },
    inProgressFunc() {
      this.inProgress = true;
    },
    endProgressFunc() {
      this.inProgress = false;
    },
    openDrawerbar() {
      this.drawer = !this.drawer;
    },
    async initUserData() {
      const self = this;
      var temp = null;
      var tempEmail = "";
      var tempProperty = [];
      self.loadingtillend();
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/my_user", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") ||
              self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          // console.log("user: ", res?.data?.result);
          temp = res?.data?.result || null;
          tempEmail = res?.data?.result?.email || "";
          // if (temp?.property[0]?.properties_detail?.length) {
          //   temp?.property[0]?.properties_detail?.map(el => {
          //     if (el?.name) tempProperty.push({
          //       _id: el._id,
          //       property_number: el?.property_number || el?._id,
          //       name: el?.name || el?._id,
          //       cover_picture: el?.cover_picture || "",
          //     })
          //   })
          // }
          if (temp?.property?.length) {
            temp.property?.map((ele) => {
              ele?.properties_detail?.map((el) => {
                if (el?.name)
                  tempProperty.push({
                    _id: el._id,
                    property_number: el?.property_number || el?._id,
                    name: el?.name || el?._id,
                  });
              });
            });
          }
        }
      } catch (error) {
        self.endloading();
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        ).then(() => self.goexit());
      } finally {
        self.endloading();
        self.userData = temp;
        self.userEmail = tempEmail;
        self.itemsPropertyOfUser = tempProperty || [];
        self.initPropertyData();

        if (
          (localStorage.getItem("Token") == "" ||
            !localStorage.getItem("Token")) &&
          self.userToken
        ) {
          localStorage.setItem("Token", self.userToken);
        }

        self.itemsUser = [
          {
            _id: 99999,
            icon: "mdi-account-outline",
            name: self.$t("SigninToolbar.editprofile"),
          },
          {
            _id: 100000,
            icon: "mdi-clipboard-outline",
            name: self.$t("SigninToolbar.todo"),
          },
          {
            _id: 100001,
            icon: "mdi-checkbox-marked-outline",
            name: self.$t("SigninToolbar.task"),
          },
        ];
      }
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      self.loadingtillend();
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${
              self?.propertyID || self?.$route?.params?.propertyId
            }`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) temp = res?.data?.result[0] || null;
          else self.goexit();
        }
      } catch (error) {
        self.endloading();
        console.log(error?.response?.data?.message || error);
        self.goexit();
      } finally {
        self.endloading();
        self.propertyData = temp;
        self.propertyData.voucher_suspend = temp.voucher_suspend || false;
        const tempPropertyNumber = self?.itemsPropertyOfUser?.find(
          (p) => p._id == self.propertyID
        );
        if (temp?.name && temp?.property_type)
          self.propertyName = `${temp?.name || ""} (ID: ${
            tempPropertyNumber?.property_number || self.propertyID
          })`;
        localStorage.setItem("property_name", temp?.name);
        self.coverImage = temp?.cover_picture
          ? `${process.env.VUE_APP_PIC_API}/${temp?.cover_picture}`
          : null;
        // team purchase
        if (temp?.team_purchase?.date_of_countdown_app) {
          var expireDate = moment(temp?.team_purchase?.date_of_countdown_app)
            .add(temp?.team_purchase?.date_for_participation || 0, "day")
            .format("YYYY-MM-DD");
          var currentDate = new Date(new Date().setUTCHours(0, 0, 0, 0));
          var countDate = new Date(
            new Date(
              moment(temp?.team_purchase?.date_of_countdown_app).add(
                temp?.team_purchase?.date_for_participation || 0,
                "day"
              )
            ).setUTCHours(0, 0, 0, 0)
          );
          if (countDate < currentDate) {
            self.remainDayOut = true;
            self.updateTimeoutAppFunc();
          } else {
            self.remainDayOut = false;
          }
        } else {
          self.remainDayOut = false;
        }

        // console.log(this.propertyData.voucher_suspend, this.$route.name);
        if (this.propertyData?.voucher_suspend) {
          this.$router
            .push({
              name: "SigninGeneralInfoScreen",
              params: {
                userToken: this?.userToken,
                propertyId: this?.propertyID,
              },
            })
            .catch(() => {});
        }

        if (self.propertyData?.voucher_suspend) {
          self.itemsNavList = [
            {
              _id: "11",
              icon: "mdi-cog-outline",
              name: self.$t("SigninSetting.setting"),
              link: "provider/setting",
              screen: null,
              submenu: [
                {
                  _id: "111",
                  name: self.$t("SigninSetting.general"),
                  link: "provider/setting/generalinfo",
                  screen: "SigninGeneralInfoScreen",
                },
              ],
            },
          ];
        } else {
          if (self.host == 'aigent-dev-hotel.gramick.dev' || self.host == 'localhost:3000') {
            self.itemsNavList = [
              {
                _id: "1",
                icon: "mdi-gift-outline",
                name: self.$t("SigninVoucherList.title"),
                link: "provider/voucher",
                screen: "SigninVoucherScreen",
                submenu: null,
              },
              {
                _id: "2",
                icon: "mdi-calendar-month-outline",
                name: self.$t("SigninCalendar.route_title"),
                link: "provider/calendar",
                screen: "SigninCalendarScreen",
                submenu: null,
              },
              {
                _id: "3",
                icon: "mdi-archive-outline",
                name: self.$t("SigninRateAndInventory.rateandinventory"),
                link: "provider/rateplanandinventory",
                screen: null,
                submenu: [
                  {
                    _id: "31",
                    name: self.$t("SigninRateAndInventory.rateplan"),
                    link: "provider/rateplanandinventory/rateplan",
                    screen: "SigninRatePlanScreen",
                  },
                  // {
                  //   _id: "32",
                  //   name: self.$t("SigninRateAndInventory.openclose"),
                  //   link: "provider/rateplanandinventory/openclose",
                  //   screen: "SigninOpenCloseScreen",
                  // },
                ],
              },
              {
                _id: "4",
                icon: "mdi-tag-multiple-outline",
                name: self.$t("SigninPromotion.route_title"),
                link: "provider/promotion",
                screen: "SigninPromotionScreen",
                submenu: null,
              },
              {
                _id: "6",
                icon: "mdi-diamond-stone",
                name: self.$t("SigninTeamPurchase.route_title"),
                link: "provider/team_purchase",
                screen: "SigninTeamPurchaseScreen",
                submenu: null,
              },
              {
                _id: "8",
                icon: "mdi-cash-multiple",
                name: self.$t("SigninFinance.title_finance"),
                link: "provider/finance",
                screen: null,
                submenu: [
                  {
                    _id: "81",
                    name: self.$t("SigninFinance.title_payout"),
                    link: "provider/finance/payout",
                    screen: "SigninPayoutScreen",
                  },
                  {
                    _id: "82",
                    name: self.$t("SigninFinance.title_invoiceandbank"),
                    link: "provider/finance/invoiceandbank",
                    screen: "SigninInvoiceAndBankScreen",
                  },
                ],
              },
              {
                _id: "11",
                icon: "mdi-cog-outline",
                name: self.$t("SigninSetting.setting"),
                link: "provider/setting",
                screen: null,
                submenu: [
                  {
                    _id: "111",
                    name: self.$t("SigninSetting.general"),
                    link: "provider/setting/generalinfo",
                    screen: "SigninGeneralInfoScreen",
                  },
                  {
                    _id: "112",
                    name: self.$t("SigninSetting.roomdetail"),
                    link: "provider/setting/room/details",
                    screen: "SigninRoomDetailsScreen",
                  },
                  {
                    _id: "113",
                    name: self.$t("SigninSetting.roomamenity"),
                    link: "provider/setting/room/amenity",
                    screen: "SigninRoomAmenityScreen",
                  },
                  {
                    _id: "114",
                    name: self.$t("SigninSetting.hotelfacility"),
                    link: "provider/setting/hotel/facilities",
                    screen: "SigninHotelFacilitiesScreen",
                  },
                  {
                    _id: "115",
                    name: self.$t("SigninSetting.activity"),
                    link: "provider/setting/activity",
                    screen: "SigninActivityScreen",
                  },
                  {
                    _id: "116",
                    name: self.$t("SigninSetting.photo"),
                    link: "provider/setting/photo",
                    screen: "SigninPhotoScreen",
                  },
                  {
                    _id: "118",
                    name: self.$t("SigninSetting.policy"),
                    link: "provider/setting/policy",
                    screen: "SigninPolicyScreen",
                  },
                  {
                    _id: "120",
                    name: self.$t("SigninSetting.nearby"),
                    link: "provider/setting/nearby",
                    screen: "SigninWhatNearByScreen",
                  },
                  {
                    _id: "121",
                    name: self.$t("SigninSetting.healthandsafety"),
                    link: "provider/setting/healthandsafety",
                    screen: "SigninHealthAndSafetyScreen",
                  },
                  {
                    _id: "123",
                    name: self.$t("SigninSetting.weekendandholiday"),
                    link: "provider/setting/weekendandholiday",
                    screen: "SigninWeekendAndHolidayScreen",
                  },
                  {
                    _id: "124",
                    name: self.$t("SigninSetting.seasonal"),
                    link: "provider/setting/seasonal",
                    screen: "SigninSeasonalScreen",
                  },
                ],
              },
              {
                _id: "12",
                icon: "mdi-account-outline",
                name: self.$t("SigninUserAndRole.title"),
                link: "provider/userandrole",
                screen: null,
                submenu: [
                  // {
                  //   _id: "12_1",
                  //   name: self.$t("SigninUserAndRole.title_create_group"),
                  //   link: "provider/userandrole/create_group",
                  //   screen: "SigninCreateGroupScreen",
                  // },
                  {
                    _id: "12_2",
                    name: self.$t("SigninUserAndRole.title_user"),
                    link: "provider/userandrole/user",
                    screen: "SigninUserScreen",
                  },
                  // {
                  //   _id: "12_3",
                  //   name: self.$t("SigninUserAndRole.title_role"),
                  //   link: "provider/userandrole/role",
                  //   screen: "SigninRoleScreen",
                  // },
                  {
                    _id: "12_4",
                    name: self.$t("SigninUserAndRole.nav_invite_user"),
                    link: "provider/userandrole/invite_user",
                    screen: "SigninInviteUserScreen",
                  },
                ],
              },
            ];
          } else {
            self.itemsNavList = [
              {
                _id: "1",
                icon: "mdi-gift-outline",
                name: self.$t("SigninVoucherList.title"),
                link: "provider/voucher",
                screen: "SigninVoucherScreen",
                submenu: null,
              },
              {
                _id: "2",
                icon: "mdi-calendar-month-outline",
                name: self.$t("SigninCalendar.route_title"),
                link: "provider/calendar",
                screen: "SigninCalendarScreen",
                submenu: null,
              },
              {
                _id: "3",
                icon: "mdi-archive-outline",
                name: self.$t("SigninRateAndInventory.rateandinventory"),
                link: "provider/rateplanandinventory",
                screen: null,
                submenu: [
                  {
                    _id: "31",
                    name: self.$t("SigninRateAndInventory.rateplan"),
                    link: "provider/rateplanandinventory/rateplan",
                    screen: "SigninRatePlanScreen",
                  },
                  // {
                  //   _id: "32",
                  //   name: self.$t("SigninRateAndInventory.openclose"),
                  //   link: "provider/rateplanandinventory/openclose",
                  //   screen: "SigninOpenCloseScreen",
                  // },
                ],
              },
              {
                _id: "4",
                icon: "mdi-tag-multiple-outline",
                name: self.$t("SigninPromotion.route_title"),
                link: "provider/promotion",
                screen: "SigninPromotionScreen",
                submenu: null,
              },
              {
                _id: "6",
                icon: "mdi-diamond-stone",
                name: self.$t("SigninTeamPurchase.route_title"),
                link: "provider/team_purchase",
                screen: "SigninTeamPurchaseScreen",
                submenu: null,
              },
              {
                _id: "8",
                icon: "mdi-cash-multiple",
                name: self.$t("SigninFinance.title_finance"),
                link: "provider/finance",
                screen: null,
                submenu: [
                  {
                    _id: "81",
                    name: self.$t("SigninFinance.title_payout"),
                    link: "provider/finance/payout",
                    screen: "SigninPayoutScreen",
                  },
                  {
                    _id: "82",
                    name: self.$t("SigninFinance.title_invoiceandbank"),
                    link: "provider/finance/invoiceandbank",
                    screen: "SigninInvoiceAndBankScreen",
                  },
                ],
              },
              {
                _id: "11",
                icon: "mdi-cog-outline",
                name: self.$t("SigninSetting.setting"),
                link: "provider/setting",
                screen: null,
                submenu: [
                  {
                    _id: "111",
                    name: self.$t("SigninSetting.general"),
                    link: "provider/setting/generalinfo",
                    screen: "SigninGeneralInfoScreen",
                  },
                  {
                    _id: "112",
                    name: self.$t("SigninSetting.roomdetail"),
                    link: "provider/setting/room/details",
                    screen: "SigninRoomDetailsScreen",
                  },
                  {
                    _id: "113",
                    name: self.$t("SigninSetting.roomamenity"),
                    link: "provider/setting/room/amenity",
                    screen: "SigninRoomAmenityScreen",
                  },
                  {
                    _id: "114",
                    name: self.$t("SigninSetting.hotelfacility"),
                    link: "provider/setting/hotel/facilities",
                    screen: "SigninHotelFacilitiesScreen",
                  },
                  {
                    _id: "115",
                    name: self.$t("SigninSetting.activity"),
                    link: "provider/setting/activity",
                    screen: "SigninActivityScreen",
                  },
                  {
                    _id: "116",
                    name: self.$t("SigninSetting.photo"),
                    link: "provider/setting/photo",
                    screen: "SigninPhotoScreen",
                  },
                  {
                    _id: "118",
                    name: self.$t("SigninSetting.policy"),
                    link: "provider/setting/policy",
                    screen: "SigninPolicyScreen",
                  },
                  {
                    _id: "120",
                    name: self.$t("SigninSetting.nearby"),
                    link: "provider/setting/nearby",
                    screen: "SigninWhatNearByScreen",
                  },
                  {
                    _id: "121",
                    name: self.$t("SigninSetting.healthandsafety"),
                    link: "provider/setting/healthandsafety",
                    screen: "SigninHealthAndSafetyScreen",
                  },
                  {
                    _id: "123",
                    name: self.$t("SigninSetting.weekendandholiday"),
                    link: "provider/setting/weekendandholiday",
                    screen: "SigninWeekendAndHolidayScreen",
                  },
                  {
                    _id: "124",
                    name: self.$t("SigninSetting.seasonal"),
                    link: "provider/setting/seasonal",
                    screen: "SigninSeasonalScreen",
                  },
                ],
              },
              {
                _id: "12",
                icon: "mdi-account-outline",
                name: self.$t("SigninUserAndRole.title"),
                link: "provider/userandrole",
                screen: null,
                submenu: [
                  // {
                  //   _id: "12_1",
                  //   name: self.$t("SigninUserAndRole.title_create_group"),
                  //   link: "provider/userandrole/create_group",
                  //   screen: "SigninCreateGroupScreen",
                  // },
                  {
                    _id: "12_2",
                    name: self.$t("SigninUserAndRole.title_user"),
                    link: "provider/userandrole/user",
                    screen: "SigninUserScreen",
                  },
                  // {
                  //   _id: "12_3",
                  //   name: self.$t("SigninUserAndRole.title_role"),
                  //   link: "provider/userandrole/role",
                  //   screen: "SigninRoleScreen",
                  // },
                  {
                    _id: "12_4",
                    name: self.$t("SigninUserAndRole.nav_invite_user"),
                    link: "provider/userandrole/invite_user",
                    screen: "SigninInviteUserScreen",
                  },
                ],
              },
            ];
          }
        }
      }
    },
    checkPropertyNameFunc() {
      const self = this;
      var tmpName = "";
      // console.log(self.propertyData)
      if (self.propertyData?.name) {
        const tempPropertyNumber = self?.itemsPropertyOfUser?.find(
          (p) => p._id == self.propertyID
        );
        if (self.propertyData?.name && self.propertyData?.property_type)
          self.propertyName = `${self.propertyData?.name || ""} (ID: ${
            tempPropertyNumber?.property_number
          })`;
        tmpName = `${self.propertyData?.name || ""} (ID: ${
          tempPropertyNumber?.property_number || "-"
        })`;
        self.coverImage = self.propertyData?.cover_picture
          ? `${process.env.VUE_APP_PIC_API}/${self.propertyData?.cover_picture}`
          : null;
      } else self.initUserData();

      // console.log("tmpName: ", tmpName, self.propertyName);

      return tmpName;
    },
    checkRoleUserFunc() {
      const self = this;
      return self.userData?.role || "provider";
    },
    changePropertyData(data) {
      const self = this;
      self.propertyID = data;
      const linkName = self.$router.history.current.name;
      self.$router
        .push({
          name: linkName,
          params: {
            userToken: self?.userToken,
            propertyId: data,
          },
        })
        .catch(() => {});
      window.location.reload();
    },
    async updateTimeoutAppFunc() {
      const self = this;

      const obj = {
        active_app: false,
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/team_purchase?property_id=${self?.propertyID}`,
          obj,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    gotoTeamPurchaseFunc() {
      const self = this;
      const link = {
        name: "SigninTeamPurchaseScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      self.gotofillmoredata(link);
    },
  },
};
</script>

<style scoped>
@import "../../styles/bootstrap/global.css";
/* @import "../../styles/signin/components.css"; */
@import "../../styles/signin/styledashboard.css";
/* @import "../../styles/signin/line-awesome/css/line-awesome.css";
@import "../../styles/signin/line-awesome/css/line-awesome.min.css"; */

.btn-exit-signup {
  cursor: pointer !important;
}
.nav-main > li.nav-signup > a:hover {
  background-color: var(--pink);
  color: #fff;
  border-color: var(--pink);
}

.navbar-shadow-signin {
  z-index: 99 !important;
  box-shadow: 0px 2px 30px 2px rgb(0 0 0 / 10%) !important;
}

@media (max-width: 991.98px) {
  .navbar-device {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    width: 300px;
    overflow: hidden;
    padding: 0;
    z-index: 1000;

    pointer-events: none;
    display: none;

    align-items: unset;
    -ms-flex-align: unset;
    background-color: #262262;
  }

  .nav-opened .navbar-device {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .closing .navbar-device {
    display: flex;
    pointer-events: auto;
  }

  .nav {
    display: flex;
    flex-wrap: inherit !important;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .position-vmain {
    position: relative;
    z-index: 1;
  }
}

.position-vmain {
  position: relative;
  z-index: 1;
}

/* .menu-appbar {
  height: 85px;
  background-color: white;
  box-shadow: 0px 2px 30px 2px rgb(0 0 0 / 10%);
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
  position: relative;
  z-index: 2;
} */

/* .appbar-nav {
  background: pink;
  position: absolute;
  left: 0;
  height: 100%;
} */

/* .appbar-brand {
  background-color: #08121E;
  width: 320px;
  height: 100%;
  float: left;
  align-items: center;
} */

.top-navbar {
  background-color: white;
  box-shadow: 0px 2px 30px 2px rgb(0 0 0 / 10%);
  height: 90px;
  align-items: center;
}

/* .content-wrapper {
  overflow: hidden;
  padding: 2.1rem 2rem 0;
} */

.menu-title {
  font-size: 19px;
  letter-spacing: 0.2px;
  font-weight: 400;
  font-size: 1.1rem;
  /* font-family: "Quicksand", Georgia, "Times New Roman", Times, serif !important;  */
}

.header-list {
  background-color: #0a1a2a;
  height: 48px;
}

.menu-list {
  border: none;
  background-color: #152638;
  color: #fff;
  cursor: pointer;
}

.span-sub-list:hover {
  margin-left: 15px;
}

.activeClass {
  border-right: #e83e8c solid 5px;
}

.user-menu {
  margin-bottom: 0px;
}

.user-menu p span {
  font-size: 12px;
  cursor: pointer;
}

.user-menu p:hover {
  font-size: 13px;
  cursor: pointer;
  margin-left: 10px;
}
</style>
