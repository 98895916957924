import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  Appbar,
  AppbarBLK,
  AppbarSignin,
  AppbarPropertyDetail,
  AppBarProvider,
} from '../components/index';
import reQ from '../views/request-account/reQ.vue';
import SignupScreen from '../views/signup/stepbg/SignupScreen.vue';
import SigninScreen from '../views/signin/SigninScreen.vue';
import i18n from '@/plugins/i18n';
import moment from 'moment';
import swal from 'sweetalert';
import axios from 'axios';

moment.locale(i18n.locale);

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    component: SignupScreen,
    redirect: () => {
      return '/signup';
    },
  },
  {
    path: '/',
    component: SignupScreen,
    redirect: () => {
      // if (store?.state?.isLogged) {
      //   return '/dashboard'
      // } else {
      return '/signup';
      // }
    },
  },
  {
    path: '/general/save',
    name: 'GeneralTermsPrintScreen',
    component: () =>
      import('../views/signup/GeneralAndPrivacy/GeneralForPrint.vue'),
  },
  {
    path: '/privacy/save',
    name: 'PrivacyTermsPrintScreen',
    component: () =>
      import('../views/signup/GeneralAndPrivacy/PrivacyForPrint.vue'),
  },
  {
    path: '.well-known/assetslinks.json',
    name: 'AssetLinksScreen',
    component: () => import('../views/public/AssetLinks.vue'),
  },
  {
    path: '/',
    name: 'AppbarBLK',
    component: AppbarBLK,
    children: [
      {
        path: 'general',
        name: 'GeneralTermsScreen',
        component: () =>
          import('../views/signup/GeneralAndPrivacy/GeneralForm.vue'),
      },
      {
        path: 'privacy',
        name: 'PrivacyTermsScreen',
        component: () =>
          import('../views/signup/GeneralAndPrivacy/PrivacyForm.vue'),
      },
      {
        path: 'home',
        name: 'HomeScreen',
        component: () => import('../views/home/HomeScreen.vue'),
      },
      {
        path: 'signup',
        name: 'SignupScreen',
        component: () => import('../views/signup/stepbg/SignupScreen.vue'),
      },
      {
        path: 'signin',
        name: 'SigninScreen',
        component: () => import('../views/signin/SigninScreen.vue'),
      },
      {
        path: 'request-account',
        name: 'request-account',
        component: () => import('../views/request-account/reQ.vue'),
      },
      {
        path: 'forget/password',
        name: 'ForgetPasswordScreen',
        component: () => import('../views/signin/ForgetPasswordScreen.vue'),
      },
      {
        path: 'reset/new-password/:passwordToken',
        name: 'ResetPasswordScreen',
        component: () => import('../views/signin/ResetPasswordScreen.vue'),
      },
      {
        path: 'auth/:provider/callback',
        component: {
          template: '<div class="auth-component"></div>',
        },
      },
      {
        path: '__/auth/handler',
        component: {
          template: '<div class="auth-component"></div>',
        },
      },
      {
        path: 'invite_user/register/:inviteId',
        name: 'SignupInviteUserScreen',
        component: () =>
          import('../views/signup/stepbg/SignupInviteUserScreen.vue'),
      },
      {
        path: 'termsandconditions',
        name: 'TermsAndConditionsScreen',
        component: () =>
          import('../views/signup/GeneralAndPrivacy/TermsAndConditions.vue'),
      },
      {
        path: 'hotel/:propertyID',
        component: () => import('../views/public/LinkApp.vue'),
      },
    ],
  },
  {
    path: '/signup',
    name: 'Appbar',
    component: Appbar,
    children: [
      {
        path: 'general',
        name: 'SignupGeneralTermsScreen',
        component: () =>
          import('../views/signup/GeneralAndPrivacy/GeneralForm.vue'),
      },
      {
        path: 'privacy',
        name: 'PrivacyTermsSignupScreen',
        component: () =>
          import('../views/signup/GeneralAndPrivacy/PrivacyForm.vue'),
      },
      {
        path: 'propertytype/:userToken',
        name: 'SignupPropertyTypeScreen',
        component: () =>
          import('../views/signup/PropertyData/PropertyTypeScreen.vue'),
      },
      {
        path: 'basic/:userToken/:propertyId',
        name: 'SignupBasicInformationScreen',
        component: () => import('../views/signup/PropertyData/BasicScreen.vue'),
      },
      {
        path: 'room/:userToken/:propertyId',
        name: 'SignupRoomScreen',
        component: () =>
          import('../views/signup/PropertyData/Room/RoomScreen.vue'),
      },
      {
        path: 'room/:userToken/:propertyId/create',
        name: 'SignupCreateRoomScreen',
        component: () =>
          import('../views/signup/PropertyData/Room/CreateRoomType.vue'),
      },
      {
        path: 'room/:userToken/:propertyId/update/:roomId',
        name: 'SignupUpdateRoomScreen',
        component: () =>
          import('../views/signup/PropertyData/Room/UpdateRoomtype.vue'),
      },
      {
        path: 'rateplan/:userToken/:propertyId',
        name: 'SignupRatePlanScreen',
        component: () =>
          import(
            '../views/signup/PropertyData/RatePlanVoucher/RatePlanVoucherScreen.vue'
          ),
      },
      {
        path: 'rateplan/:userToken/:propertyId/create',
        name: 'SignupCreateRatePlaneScreen',
        component: () =>
          import(
            '../views/signup/PropertyData/RatePlanVoucher/CreateRatePlan.vue'
          ),
      },
      {
        path: 'rateplan/:userToken/:propertyId/update/:rateplanId',
        name: 'SignupUpdateRatePlaneScreen',
        component: () =>
          import(
            '../views/signup/PropertyData/RatePlanVoucher/UpdateRatePlan.vue'
          ),
      },
      {
        path: 'facilities/:userToken/:propertyId',
        name: 'SignupFacilitiesScreen',
        component: () =>
          import('../views/signup/PropertyData/FacilitiesScreen.vue'),
      },
      {
        path: 'pictures/:userToken/:propertyId',
        name: 'SignupPicturesScreen',
        component: () =>
          import('../views/signup/PropertyData/PicturesScreen.vue'),
      },
      {
        path: 'policy/:userToken/:propertyId',
        name: 'SignupPolicyScreen',
        component: () =>
          import('../views/signup/PropertyData/PolicyScreen.vue'),
      },
      {
        path: 'payment/:userToken/:propertyId',
        name: 'SignupPaymentScreen',
        component: () =>
          import('../views/signup/PropertyData/PaymentScreen.vue'),
      },
      {
        path: 'register/:userToken/:propertyId',
        name: 'SignupRegisterScreen',
        component: () =>
          import('../views/signup/PropertyData/RegisterScreen.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'AppbarSignin',
    component: AppbarSignin,
    children: [
      {
        path: 'management/property/:userToken',
        name: 'SigninPropertyManagementScreen',
        component: () =>
          import('../views/signin/management/PropertyManagement.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'AppbarPropertyDetail',
    component: AppbarPropertyDetail,
    children: [
      {
        path: 'propertydetail/:userToken/:propertyId',
        name: 'SignupPropertyDetailScreen',
        component: () =>
          import('../views/signup/AccountDetail/PropertyDetail.vue'),
      },
      {
        path: 'ownerdetail/:userToken/:propertyId',
        name: 'SignupOwnerDetailScreen',
        component: () =>
          import('../views/signup/AccountDetail/OwnerDetail.vue'),
      },
      {
        path: 'managerdetail/:userToken/:propertyId',
        name: 'SignupManagerDetailScreen',
        component: () =>
          import('../views/signup/AccountDetail/ManagerDetail.vue'),
      },
      {
        path: 'confirmation/:userToken/:propertyId',
        name: 'SignupConfirmationScreen',
        component: () =>
          import('../views/signup/AccountDetail/Confirmation.vue'),
      },
      {
        path: 'success/:userToken/:propertyId',
        name: 'SignupRegisterSuccessScreen',
        component: () => import('../views/signup/AccountDetail/Detail.vue'),
      },
    ],
  },
  {
    path: '/provider/calendar/:userToken/:propertyId',
    name: 'SigninCalendarScreen',
    meta: {
      title: 'Calendar',
    },
    component: () => import('../views/signin/calendar/index.vue'),
  },
  {
    path: '/provider',
    name: 'AppBarProvider',
    component: AppBarProvider,
    children: [
      {
        path: 'dashboard/:userToken/:propertyId',
        name: 'SigninDashboardScreen',
        component: () => import('../views/signin/Dashboard.vue'),
      },
      {
        path: 'setting/room/details/:userToken/:propertyId',
        name: 'SigninRoomDetailsScreen',
        meta: {
          title: 'Settings',
        },
        component: () =>
          import('../views/signin/setting/RoomDetails/Rooms.vue'),
      },
      {
        path: 'setting/room/details/create/:userToken/:propertyId',
        name: 'SigninRoomDetailsCreateScreen',
        meta: {
          title: 'Settings',
        },
        component: () =>
          import('../views/signin/setting/RoomDetails/Create.vue'),
      },
      {
        path: 'setting/room/details/update/:userToken/:propertyId/:roomId',
        name: 'SigninRoomDetailsUpdateScreen',
        meta: {
          title: 'Settings',
        },
        component: () =>
          import('../views/signin/setting/RoomDetails/Update.vue'),
      },
      {
        path: 'setting/room/amenity/:userToken/:propertyId',
        name: 'SigninRoomAmenityScreen',
        meta: {
          title: 'Settings',
        },
        component: () =>
          import('../views/signin/setting/RoomAmenity/Rooms.vue'),
      },
      {
        path: 'setting/hotel/facilities/:userToken/:propertyId',
        name: 'SigninHotelFacilitiesScreen',
        meta: {
          title: 'Settings',
        },
        component: () =>
          import('../views/signin/setting/HotelFacilities/Hotel.vue'),
      },
      {
        path: 'setting/photo/:userToken/:propertyId',
        name: 'SigninPhotoScreen',
        meta: {
          title: 'Settings',
        },
        component: () => import('../views/signin/setting/Photo/Photo.vue'),
      },
      {
        path: 'setting/nearby/:userToken/:propertyId',
        name: 'SigninWhatNearByScreen',
        meta: {
          title: 'Settings',
        },
        component: () =>
          import('../views/signin/setting/WhatNearBy/WhatNearBy.vue'),
      },
      {
        path: 'setting/healthandsafety/:userToken/:propertyId',
        name: 'SigninHealthAndSafetyScreen',
        meta: {
          title: 'Settings',
        },
        component: () =>
          import('../views/signin/setting/HealthAndSafety/HealthAndSafety.vue'),
      },
      {
        path: 'setting/activity/:userToken/:propertyId',
        name: 'SigninActivityScreen',
        meta: {
          title: 'Settings',
        },
        component: () =>
          import('../views/signin/setting/Activity/Activity.vue'),
      },
      {
        path: 'user/profile/:userToken/:propertyId',
        name: 'SigninYourProfileScreen',
        component: () =>
          import('../views/signin/setting/YourProfile/Profile.vue'),
      },
      {
        path: 'setting/generalinfo/:userToken/:propertyId',
        name: 'SigninGeneralInfoScreen',
        meta: {
          title: 'Settings',
        },
        component: () =>
          import('../views/signin/setting/GeneralInfo/GeneralInfo.vue'),
      },
      {
        path: 'setting/policy/:userToken/:propertyId',
        name: 'SigninPolicyScreen',
        meta: {
          title: 'Settings',
        },
        component: () => import('../views/signin/setting/Policy/Policy.vue'),
      },
      {
        path: 'rateplanandinventory/rateplan/:userToken/:propertyId',
        name: 'SigninRatePlanScreen',
        meta: {
          title: 'Rate Plan',
        },
        component: () =>
          import('../views/signin/rateplanandinventory/rateplan/RatePlan.vue'),
      },
      {
        path: 'rateplanandinventory/rateplan/create/:userToken/:propertyId',
        name: 'SigninRatePlanCreateScreen',
        meta: {
          title: 'Rate Plan',
        },
        component: () =>
          import(
            '../views/signin/rateplanandinventory/rateplan/AddRatePlan.vue'
          ),
      },
      {
        path: 'rateplanandinventory/rateplan/fix/:userToken/:propertyId/:rateplanId',
        name: 'SigninRatePlanUpdateFixScreen',
        meta: {
          title: 'Rate Plan',
        },
        component: () =>
          // import('../views/signin/rateplanandinventory/rateplan/AddFixRatePlan.vue')
          import('../views/signin/rateplanandinventory/rateplan/NewAddFix.vue'),
      },
      {
        path: 'rateplanandinventory/rateplan/new/fix/:userToken/:propertyId',
        name: 'SigninRatePlanNewAddFixScreen',
        meta: {
          title: 'Rate Plan',
        },
        component: () =>
          import('../views/signin/rateplanandinventory/rateplan/NewAddFix.vue'),
      },
      {
        path: 'rateplanandinventory/rateplan/full/:userToken/:propertyId/:rateplanId',
        name: 'SigninRatePlanUpdateFullScreen',
        meta: {
          title: 'Rate Plan',
        },
        component: () =>
          // import('../views/signin/rateplanandinventory/rateplan/AddFullFlexRatePlan.vue')
          import(
            '../views/signin/rateplanandinventory/rateplan/NewAddFull.vue'
          ),
      },
      {
        path: 'rateplanandinventory/rateplan/new/full/:userToken/:propertyId',
        name: 'SigninRatePlanNewAddFullScreen',
        meta: {
          title: 'Rate Plan',
        },
        component: () =>
          import(
            '../views/signin/rateplanandinventory/rateplan/NewAddFull.vue'
          ),
      },
      {
        path: 'rateplanandinventory/opencloseroom/:userToken/:propertyId',
        name: 'SigninOpenCloseScreen',
        meta: {
          title: 'Rate Plan',
        },
        component: () =>
          import('../views/signin/rateplanandinventory/openclose/index.vue'),
      },
      {
        path: 'setting/seasonal/:userToken/:propertyId',
        name: 'SigninSeasonalScreen',
        meta: {
          title: 'Rate Plan',
        },
        component: () => import('../views/signin/setting/Seasonal/index.vue'),
      },
      {
        path: 'setting/seasonal/add/:userToken/:propertyId',
        name: 'SigninSeasonalAddScreen',
        component: () =>
          import('../views/signin/setting/Seasonal/AddSeasonal.vue'),
      },
      {
        path: 'setting/seasonal/update/:userToken/:propertyId/:seasonId',
        name: 'SigninSeasonalUpdateScreen',
        component: () =>
          import('../views/signin/setting/Seasonal/UpdateSeasonal.vue'),
      },
      {
        path: 'setting/weekendandholiday/:userToken/:propertyId',
        name: 'SigninWeekendAndHolidayScreen',
        component: () =>
          import('../views/signin/setting/WeekendAndHoliday/index.vue'),
      },
      {
        path: 'promotion/:userToken/:propertyId',
        name: 'SigninPromotionScreen',
        meta: {
          title: 'Promotion',
        },
        component: () => import('../views/signin/promotion/index.vue'),
      },
      {
        path: 'promotion/campaign/edit/:userToken/:propertyId/:campaignId',
        name: 'SigninCampaignEditScreen',
        meta: {
          title: 'Promotion',
        },
        component: () => import('../views/signin/promotion/EditCampaign.vue'),
      },
      {
        path: 'promotion/promotion/type/:userToken/:propertyId',
        name: 'SigninPromotionTypeScreen',
        meta: {
          title: 'Promotion',
        },
        component: () => import('../views/signin/promotion/PromotionType.vue'),
      },
      {
        path: 'promotion/promotion/nation/:userToken/:propertyId/:promotionTypeId',
        name: 'SigninPromotionTypeNationScreen',
        meta: {
          title: 'Promotion',
        },
        component: () =>
          import('../views/signin/promotion/PromotionNation.vue'),
      },
      {
        path: 'promotion/promotion/nation/:userToken/:propertyId/:promotionTypeId/:promotionId',
        name: 'SigninPromotionTypeNationUpdateScreen',
        meta: {
          title: 'Promotion',
        },
        component: () =>
          import('../views/signin/promotion/PromotionNation.vue'),
      },
      {
        path: 'promotion/promotion/basic/:userToken/:propertyId/:promotionTypeId',
        name: 'SigninPromotionTypeBasicScreen',
        meta: {
          title: 'Promotion',
        },
        component: () => import('../views/signin/promotion/PromotionBasic.vue'),
      },
      {
        path: 'promotion/promotion/basic/:userToken/:propertyId/:promotionTypeId/:promotionId',
        name: 'SigninPromotionTypeBasicUpdateScreen',
        meta: {
          title: 'Promotion',
        },
        component: () => import('../views/signin/promotion/PromotionBasic.vue'),
      },
      {
        path: 'promotion/promotion/advanced/:userToken/:propertyId/:promotionTypeId',
        name: 'SigninPromotionTypeAdvancedScreen',
        meta: {
          title: 'Promotion',
        },
        component: () =>
          import('../views/signin/promotion/PromotionNation.vue'),
      },
      {
        path: 'promotion/promotion/advanced/:userToken/:propertyId/:promotionTypeId/:promotionId',
        name: 'SigninPromotionTypeAdvancedUpdateScreen',
        meta: {
          title: 'Promotion',
        },
        component: () =>
          import('../views/signin/promotion/PromotionNation.vue'),
      },
      {
        path: 'promotion/promotion/free/:userToken/:propertyId/:promotionTypeId',
        name: 'SigninPromotionTypeFreeScreen',
        meta: {
          title: 'Promotion',
        },
        component: () => import('../views/signin/promotion/PromotionFree.vue'),
      },
      {
        path: 'promotion/promotion/free/:userToken/:propertyId/:promotionTypeId/:promotionId',
        name: 'SigninPromotionTypeFreeUpdateScreen',
        meta: {
          title: 'Promotion',
        },
        component: () => import('../views/signin/promotion/PromotionFree.vue'),
      },
      {
        path: 'team_purchase/:userToken/:propertyId',
        name: 'SigninTeamPurchaseScreen',
        meta: {
          title: 'Team Purchase',
        },
        component: () => import('../views/signin/teampurchase/index.vue'),
      },
      {
        path: 'voucher/:userToken/:propertyId',
        name: 'SigninVoucherScreen',
        meta: {
          title: 'Voucher',
        },
        component: () => import('../views/signin/voucherlist/index.vue'),
      },
      {
        path: 'voucher/detail/:userToken/:propertyId',
        name: 'SigninVoucherDetailScreen',
        meta: {
          title: 'Voucher',
        },
        component: () => import('../views/signin/voucherlist/Detail.vue'),
      },
      // {
      //     path: '/provider/calendar/:userToken/:propertyId',
      //     name: 'SigninCalendarScreen',
      //     component: () =>
      //         import('../views/signin/calendar/calendar.vue')
      // },
      {
        path: 'userandrole/create_group/:userToken/:propertyId',
        name: 'SigninCreateGroupScreen',
        meta: {
          title: 'User and Role',
        },
        component: () => import('../views/signin/userandrole/CreateGroup.vue'),
      },
      {
        path: 'userandrole/user/:userToken/:propertyId',
        name: 'SigninUserScreen',
        meta: {
          title: 'User and Role',
        },
        component: () => import('../views/signin/userandrole/user/index.vue'),
      },
      {
        path: 'userandrole/create_user/:userToken/:propertyId',
        name: 'SigninCreateUserScreen',
        meta: {
          title: 'User and Role',
        },
        component: () =>
          import('../views/signin/userandrole/user/CreateUser.vue'),
      },
      {
        path: 'userandrole/invite_user/:userToken/:propertyId',
        name: 'SigninInviteUserScreen',
        meta: {
          title: 'User and Role',
        },
        component: () =>
          import('../views/signin/userandrole/user/InviteUser.vue'),
      },
      {
        path: 'userandrole/role/:userToken/:propertyId',
        name: 'SigninRoleScreen',
        meta: {
          title: 'User and Role',
        },
        component: () => import('../views/signin/userandrole/Role.vue'),
      },
      {
        path: 'finance/payout/:userToken/:propertyId',
        name: 'SigninPayoutScreen',
        meta: {
          title: 'Payout',
        },
        component: () => import('../views/signin/finance/PayoutScreen.vue'),
      },
      {
        path: 'finance/invoiceandbank/:userToken/:propertyId',
        name: 'SigninInvoiceAndBankScreen',
        component: () =>
          import(
            '../views/signin/finance/invoiceandbank/InvoiceAndBankScreen.vue'
          ),
      },
      {
        path: 'finance/invoice_edit/:userToken/:propertyId',
        name: 'SigninCreateInvoiceScreen',
        component: () =>
          import(
            '../views/signin/finance/invoiceandbank/EditInvoiceScreen.vue'
          ),
      },
      {
        path: 'finance/invoice_edit/:userToken/:propertyId/:invoiceId',
        name: 'SigninEditInvoiceScreen',
        component: () =>
          import(
            '../views/signin/finance/invoiceandbank/EditInvoiceScreen.vue'
          ),
      },
      {
        path: 'finance/invoice_edit_info/:userToken/:propertyId',
        name: 'SigninCreateInvoiceInfoScreen',
        component: () =>
          import(
            '../views/signin/finance/invoiceandbank/EditInvoiceInfoScreen.vue'
          ),
      },
      {
        path: 'finance/invoice_edit_info/:userToken/:propertyId/:invoiceId',
        name: 'SigninEditInvoiceInfoScreen',
        component: () =>
          import(
            '../views/signin/finance/invoiceandbank/EditInvoiceInfoScreen.vue'
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  resetRouter,
});

export function resetRouter() {
  const newRouter = routes;
  router.matcher = newRouter.matcher; // reset router
  router.options.routes = [];
}

router.beforeEach(async (to, from, next) => {
  var text = '';
  const languate =
    localStorage?.getItem('language') || self?.$store?.state?.language;
  if (languate == 'en') {
    text = 'Please update your financial information before getting started.';
  } else if (languate == 'th') {
    text = 'กรุณาอัพเดทข้อมูลทางการเงินของคุณก่อนเริ่มใช้งาน';
  } else {
    text = '请在开始使用之前更新您的财务信息。';
  }
  if (to.meta.title) {
    if (to.path != '/') {
      try {
        const res = await axios.get(
          process.env.VUE_APP_API +
            `/finance/invoice?property=${to.params.propertyId}`,
          {
            headers: {
              Authorization: to.params.userToken,
            },
          }
        );

        return next();
      } catch (error) {
        // check http status code
        if (error.response.status == 401) {
          localStorage.removeItem('Token');
          localStorage.removeItem('userEmail');
          return router.push({ name: 'SigninScreen' });
        } else {
          swal({
            icon: 'warning',
            title: text,
            closeOnClickOutside: false,
            closeOnEsc: false,
          }).then((value) => {
            if (value) {
              return router.push({
                name: 'SigninInvoiceAndBankScreen',
                params: to.params,
              });
            }
          });
        }
      }
    } else {
      return next();
    }
  } else {
    return next();
  }
});

export default router;
